import React from 'react'
import { Modal, Button } from "react-bootstrap"

import serviceIcon from '../../images/serviceIcon1.svg'
import serviceIconDark from '../../images/serviceIconDark.png'
import arrowRight from '../../images/arrowRight.svg'
import Slider from "react-slick";
import { useState } from 'react';


export const Section5 = ({darkMode}) => {
    const [show, setShow] = useState(false);
    var [currentDemo,setCurrentDemo] =useState({})

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      const handleDemoClose = () => setShow(false);
      const handleDemoShow = (item) => {
          setShow(true)
          setCurrentDemo(item)
          console.log(item);
      }
  return (
    <section className='specialServiceSec' id='sec5'>
        <div className="container">
        <h2 className='text-center'>Our Special Services</h2>
        <div>
        <Slider {...settings}>
            <div>
        <div className="row mx-0 pt-3">
            {specialServiceData1.map((item,id)=>(

            <div className="col-lg-4 col-md-6">
            <div className="serviceCard animatable fadeInUp ">
                <div className='content'>
                <div className='innerContent'>
                {window.location.pathname == "/" || window.location.pathname == "/contact" || window.location.pathname == "/about" || window.location.pathname == "/blog" ?
                    <img className='icon' src={serviceIcon} alt="serviceIcon" width="70px" height="70px" />
                :
                    <img className='icon' src={serviceIconDark} alt="serviceIcon" width="70px" height="70px" />
                }
                    <h6>{item.name}</h6>
                </div>
                <div className='desc' dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
                <div className="btnDiv">
                {/* <button className='btn readMore'>Read More</button> */}
                <button className='btn readMore'></button>
                <div className="demoBtnDiv">
                    <div className="shadow"></div>
                    <button className='btn getDemo' onClick={()=>handleDemoShow(item)}>Get a demo <img src={arrowRight} alt="get_a_demo" width="13px" height="13px"/></button>
                </div>
                </div>
            </div>
            </div>

            ))}
        

        </div>
            </div>
            <div>
            <div className="row mx-0 pt-3">
            {specialServiceData2.map((item,id)=>(

                <div className="col-lg-4 col-md-6">
                    <div className="serviceCard animatable fadeInUp ">
                        <div className='content'>
                        <div className='innerContent'>
                            <img className='icon' src={serviceIcon} alt="service" width="70px" height="70px"/>
                            <h6>{item.name}</h6>
                        </div>
                        <div className='desc' dangerouslySetInnerHTML={{__html: item.description}} />
                        </div>
                        <div className="btnDiv">
                        {/* <button className='btn readMore'>Read More</button> */}
                        <button className='btn readMore'></button>
                        <div className="demoBtnDiv">
                        <div className="shadow"></div>
                        <button className='btn getDemo' onClick={()=>handleDemoShow(item)}>Get a demo <img src={arrowRight} alt="get_a_demo" width="13px" height="13px"/></button>
                        </div>                    
                    </div>
                    </div>
                </div>

    ))}

            </div>
            </div>
            
            </Slider>
        </div>
        </div>
        <Modal show={show} onHide={handleDemoClose}>
    
    <Modal.Body className={darkMode?'darkMode demoModal':'demoModal'}  >
      <div className="heading">
        <h2>{currentDemo?.name}</h2>
        <i class="fas fa-times" onClick={handleDemoClose}></i>
      </div>
      <div className="mainContent pb-0">
        <div className='desc' dangerouslySetInnerHTML={{__html: currentDemo?.description}} />
          {currentDemo.category &&
          <div className="row jc-center pt-4">
           { currentDemo?.category.map((item,key)=>(
              <div className="col-sm-4 mb-3" key={key}>
                <div className="descBox">
                  <h6>{item}</h6>
                </div>
              </div>
            ))}
          
          </div>
          }
        </div>
      <div className="mainContent">
      <button type="submit" className="btn sendBtn  d-block">Book a demo</button>
      </div>
      <div>

      </div>
      
    </Modal.Body>
    {/* <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button>
    </Modal.Footer> */}
  </Modal>
    </section>
  )
}
const specialServiceData1 = [
    {
      name:"DAPPS DEVELOPMENT",
      description:"From Web3.0 integrations to any kind of token integration dappomatics offering all kinds of solution, We've an experience of building various kind of decentralized application. ",
       
    },
    {
      name:"SOLIDITY DEVELOPMENT/Auditing",
      description:"We build solidity smartcontracts with focusing mostly on security and gas optimization. Dappomatics offers development of smartcontract with any protocols like (ERC20, ERC721, ERC721A, ERC1155, ERC165, ERC884, and ETC) and any customized solution you need, also offering detailed testing and audit reports for your contracts",
      category:[
        "Cutomized Solidity Contracts",
        "Staking Contracts",
        "Escrow Contracts",
        "Integrating Decentralized Oracles",
        "Audit Reports"
      ]
    },
    {
      name:"NFT DEVELOPMENT SOLUTIONS",
      description:`We are developing all NFT related applications and also suggesting best solutions for theirs project. <br />  We're offering <br />  `,
       category:[
        "NFT Token Development",
        "NFT Marketplace Development", 
        "Cross-Chain Nft Development", 
        "NFT Art Tokenization", 
        "NFT Lending Development"
      ]
   
    },
    {
      name:"LAUNCHPAD PLATFORM DEVELOPMENT",
      description:"",
       
    },
    {
      name:"DIGITAL TOKENIZATION",
      description:"",
       
    },
    {
      name:"DAO GOVERNANCE",
      description:"Knowing the potential of DAO, our experts started to provide DAO related services and solutions. We have transformed into a decentralized self-organized DAO development company that has expanded DAO related services such as DAO platform development, DAO application development, DAO powered NFT platform development and more.",
      category:[
        "Voting Token Development",
        "DAO Governance Protocols Development",
        "DAO Rewards EcoSystem"
      ]      
    },
];
  const specialServiceData2 = [
    {
      name:"CUSTOM BLOCKCHAIN DEVELOPMENT",
      description:"Blockchain technologies are driving disruption in multiple industries including: Health Care, Education, Fintech, Insurance, supply chain, and ETC.<br /> Dappomatics blockchain development service helps businesses plan, build, and implement applications, assets, and functions.<br /> We help partners select the most appropriate blockchain technology from a range of industry leaders, including BitShares, EOS, Hyperledger, and Ethereum.",
      category:[
        "POS/POW Blockchains Development",
        "Ethereum Fork",
        "Customized Blockchain Network",
        "HyperLedger",
        "Lightning Network"
      ] 
    },
    {
      name:"MULTICHAIN SOLUTIONS",
      description:"",
       
    },
    {
      name:"BLOCKCHAIN CONSULTANCY",
      description:"We share our experiences on the projects  we have done. Our team understands the pros and cons of different platforms. We will provide strategic consulting to hands on expereince and provide custom build blockchain solutions.  Dappomatics biggest strength as blockchain consulting company is our experience in R&D on various web3 and blockchain stacks.",
       
    },
    {
      name:"BLOCKCHAIN TRAININGS",
      description:"Become a well-paid blockchain developer by joining dappomatics blockchain developer bootcamp. You will acquire all the necessary skills  to enter the blockchain industry as a developer. We will teach you how to create your first blockchain application with Ethereum, Web3.js and Solidity smart contracts. You don't need to know anything about blockchain to keep track. Dappomatics will teach you from scratch. Use this step-by-step guide with sample code and written instructions to start your blockchain development journey.",
       
    },
    {
      name:"SOLANA DEVELOPMENT",
      description:"",
      category:[
        "Solana Marketplace",
        "Candymachine Development",
        "Gumdrop",
        "Metaplex",
        "SPL Tokens Development"
      ]
       
    },
    {
      name:"WEB APPLICATION SOLUTIONS",
      description:"",
      category:[
        "Web Apps Designing",
        "Ecommerce Web Apps",
        "Customized Dashboards",
        "Logistics Web Apps"
      ]
    },
];