import React from 'react'


export const Section2 = () => {
  return (
    <section className='secTwo' id='sec2'>
    <div className="row mx-0">
        <div className="col">
            <div className="count">7+Years </div>
            <div className="text">Experience in Defi & Nfts</div>
        </div>
        <div className="col">
            <div className="count">20 Employees </div>
            <div className="text">Experience in Defi & Nfts</div>
        </div>
        <div className="col">
            <div className="count">100+Projects </div>
            <div className="text">Experience in Defi & Nfts</div>
        </div>
    </div>
</section>
  )
}
