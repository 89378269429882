import React from 'react'
import kidNft from '../../images/kidNft.jpg'

export const Section6 = () => {
  return (
    <section className='secFour' id='sec6'>
        <img className='nftImg' src={kidNft} alt="nft" />
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <h1 className='animatable fadeInUp '>Nfts For <br /> New Kids Show</h1>
                    <p className='animatable fadeInUp '>The prodco has teamed up with artist Pablo Stanley to bring his Robotos NFT collection (pictured) to screen. The series is currently in development, but no additional details were available at press time. Stanley’s NFT range spans more than 10,000 randomly generated droid characters, which have been made into digital tokens with different levels of rarity.</p>
                    <button className='animatable fadeInUp '>Read Full Blog</button>
                </div>
            </div>
        </div>
    </section>
  )
}
