import React from 'react'
import getStartElement from '../../images/getStartElement.svg'
import getStartElement1 from '../../images/getStartElement1.svg'

export const Section27 = () => {
  return (
    
    <div id='sec27'>
        <img className='img-fluid w-100' src={getStartElement} alt="" />
        <section className='sec14'>
            <div className="container">
                <h1>Your brand, like you’ve never seen it before</h1>
                <button className=''>Get Started</button>
            </div>
        </section>
        <img className='img-fluid w-100' src={getStartElement1} alt="" />
    </div>
  )
}
