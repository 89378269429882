import React from 'react'
import projectsBanner from '../../images/projectsBanner.png'

export const Section15 = () => {
  return (
    <section className='secSeven' id='sec15'>
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-3 ai-fs">
                    <div className="exprerience animatable bounceInLeft">
                        <h3>7 years+</h3>
                        <h4>Experience</h4>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="imgDiv">
                        <img src={projectsBanner} alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="content">
                        <div className="projects animatable bounceInRight ">
                            <h5>1<span>K+</span></h5>
                            <p>Web 3 Apps</p>
                        </div>
                        <div className="projects animatable bounceInRight animationDelay200">
                            <h5>30<span>+</span></h5>
                            <p>Active Designers</p>
                        </div>
                        <div className="projects animatable bounceInRight animationDelay400">
                            <h5>200<span>+</span></h5>
                            <p>Active Projects</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
