import React from 'react'
import prebuiltIco from '../../images/prebuiltIco.svg'
import ovalEffect from '../../images/ovalEffect.png'

export const Section16 = () => {
  return (
    <section className='secEight' id='sec16'>
    <img src={ovalEffect} alt="effect1" className="effect" />
    <div className="container pr">
        <h1>It all starts with contracts.</h1>
        <div className="row">
            <div className="col-md-6">
                <div className="eightCard">
                    <h2>Prebuilt</h2>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Secure</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Optimized</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Conveient</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <button className='btnGradient btn-block py-3'>Explore prebuilt Contracts</button>
                </div>
            </div>
            <div className="col-md-6">
                <div className="eightCard">
                    <h2>Build Your Own</h2>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Starter Templates</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Contract Kit</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">Dashboards & SDKs</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <button className='btnGradient btn-block py-3'>Explore prebuilt Contracts</button>

                </div>
            </div>
        </div>
    </div>
</section>
  )
}
