import React from 'react'
import blockchainIcon from '../../images/blockchainIcon.webp'

export const GetInTouch = ({handleShow}) => {
  return (
    <div className="container">
        <section className='getInTouchSec'>
        

        <div className='row contentMain'>
            <div className="col-xl-6 col-lg-7 col-md-8">
            <div className='col1'>
                <img src={blockchainIcon} alt="blockchain" width={100} height={115.1} />
                <h3>Let's build great things together.</h3>
            </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-4">
            <div className='col2'>
                <button className='btn getInTouchBtn animatable bounceInLeft ' onClick={()=>handleShow("Get In Touch")} >Get In Touch</button>
            </div>
            </div>
        </div>
        </section>
    </div>
  )
}
