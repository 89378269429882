import React from 'react'
import ammar from '../../images/ammar.webp'
import ahsan from '../../images/ahsan.webp'
import affan from '../../images/affan.webp'
import ibtisam from '../../images/ibtisam.webp'
import farrukh from '../../images/Farrukh.webp'
import mustafa from '../../images/Mustafa.webp'
import effect1 from '../../images/effect1.png'
import teamEffect1 from '../../images/teamEffect1.png'
import Slider from "react-slick";

const settings = {
  className: "center",
  dots: true,
  infinite: true,
  speed: 500,
  // centerMode: true,
  // centerPadding: "60px",

  slidesToShow: 2,
  slidesToScroll: 1,
  
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
   
  ]
};
const teamData =[
  {
    name:"Affan Khan",
    designation:"VP Engineering",
    linkedIn:"https://www.linkedin.com/in/web3-engineer/",
    desc:"lorem epsum",
    image:affan
  },
  {
    name:"Ibtisam Ahmad",
    designation:"Cheif Operating Officer",
    linkedIn:"https://www.linkedin.com/in/ibtisam-ahmed-42447211a/",
    desc:"With a passion for Blockchain, experience as a Full Stack Developer and education in Physics background Ibtisam is well-equipped to tackle any challenge. As COO of Dappomatics we are leveraging our passion for technology to create groundbreaking solutions.",
    image:ibtisam
  },
  {
    name:"Ammar Khalid",
    designation:"Tech Lead",
    linkedIn:"https://www.linkedin.com/in/ammarkhalid123/",
    desc:"lorem epsum",
    image:ammar
  },
  {
    name:"Ahsan Iqbal",
    designation:"Full Stack Developer",
    linkedIn:"https://www.linkedin.com/in/ahsan-iqbal-b27212194/",
    desc:"lorem epsum",
    image:ahsan
  },
  {
    name:"Mustafa Ahmed",
    designation:"Full Stack Developer",
    twitter:"https://twitter.com/mustafaalvi21",
    image:mustafa
  },
]

export const Section12 = () => {
  return (
    <section className='teamSecNew' id='sec12'>
        <img src={teamEffect1} alt="effect1" className="effect" />
        <img src={effect1} alt="effect1" className="effect1" />
        <div className="container pr z-1">
        <div className="row">
            <div className="col-lg-4  ">
              <div className='content'>
                  <h2>Our team</h2>
                  <p>The media plays an important role in marketing and communicating messages to the public. A medium that is attractive and contains messages</p>
                  
                  <button className="btnGradient py-2 px-5">Explore</button>
              </div>
            </div>
            <div className="col-lg-8 ">
            <Slider {...settings}>
              {teamData.map((item,key)=>(
                <TeamMember 
                name={item.name}
                designation={item.designation}
                desc={item.desc}
                image={item?.image}
                linkedIn={item?.linkedIn} 
                twitter={item?.twitter} 
                />
              ))}
             
            </Slider>
            </div>  
        </div>
        </div>
    </section>
  )
}


const TeamMember = ({name,designation,desc,image,fb ,linkedIn,twitter}) =>{
    return(
      <div className="teamCard">
        <div className="imgDiv">
          <img className='img-fluid' src={image} alt="" />
          <div class="content1">
            <p>{designation}</p>
            <h3 className='desc'>{desc}</h3>
            <div>
              {linkedIn &&
               <a href={linkedIn} target='_blank' rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
              }
              {twitter &&
               <a href={twitter} target='_blank' rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
              }
            </div>
          </div>
        </div>
        <h1 className="name text-center">{name}</h1>
      </div>
    )
  }