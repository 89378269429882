import React from 'react'
import nftMarketBanner from '../../images/nftMarketBanner.png'
import effect1 from '../../images/effect1.png'

export const Section23 = () => {
  return (
    
    <section className='sec12 frame1' id='sec23'>
        <img src={effect1} alt="effect1" className="effect" />
        
        <div className="pinkBar"></div>
        <h1 className='pr'>Our NFT Marketpace Demo</h1>
        <div className="container">
            <img className='img-fluid frame3' src={nftMarketBanner} alt="banner" />
        </div>
    </section>
  )
}
