import React from 'react'
import effect1 from '../../images/effect1.png'
import partner1 from '../../images/partner1.png'
import partner2 from '../../images/partner2.png'
import partner3 from '../../images/partner3.png'
import partner4 from '../../images/partner4.png'
import partner5 from '../../images/partner5.png'

export const Section4 = () => {
  return (
    <section className='partnerSec' id='sec4'>
    <img src={effect1} alt="effect1" className="effect" />
    <div className="container pr">
        <h1>Our Partners</h1>
        <div className="row ai-center jc-center">
            <div className="col-md col-sm-4 col-6 mb-2">
                <img src={partner1} alt="" className="img-fluid animatable fadeInUp " />
            </div>
            <div className="col-md col-sm-4 col-6 mb-2">
                <img src={partner2} alt="" className="img-fluid animatable fadeInUp animationDelay100" />
            </div>
            <div className="col-md col-sm-4 col-6 mb-2">
                <img src={partner3} alt="" className="img-fluid animatable fadeInUp animationDelay200" />
            </div>
            <div className="col-md col-sm-4 col-6 mb-2">
                <img src={partner4} alt="" className="img-fluid animatable fadeInUp animationDelay300" />
            </div>
            <div className="col-md col-sm-4 col-6 mb-2">
                <img src={partner5} alt="" className="img-fluid animatable fadeInUp animationDelay400" />
            </div>
        </div>
    </div>
</section>
  )
}
