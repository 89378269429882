import React from 'react'
import effect1 from '../../images/effect1.png'

export const Section1 = ({heading, desc, image}) => {
  return (
    <section className='secOne' id='sec1'>
    <img src={effect1} alt="effect1" className="effectRight" />
    <img src={effect1} alt="effect1" className="effectLeft" />

    <div className="container">
        <div className="row ai-center">
            <div className="col-md-6">
                <h1>{heading}</h1>
                <p>{desc}</p>  
                <div className="btnMain">
                    <button className='btnGradient py-2 px-5 mr-3'>Explore</button>
                    <button className='btnGradientoutline py-2 px-5'>Create</button>
                </div>
            </div>
            <div className="col-md-6">
                <div className="imgDiv">
                    <img className='img-fluid' src={image} alt="DEFI" />
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
