import React, { useEffect, useState } from 'react'

import { Navbar } from '../Navbar'
import Slider from "react-slick";

import logo from '../../images/Logo.webp'
import { animate } from '../../assets/animate'
import defiPlatformBanner from '../../images/defiPlatformBanner.png'
import defiPlatformBanner2 from '../../images/defiPlatformBanner2.png'
import logoDark from '../../images/logoDark.png'
import Ellipse from '../../images/Ellipse.webp'

import ammar from '../../images/ammar.webp'
import ahsan from '../../images/ahsan.webp'
import affan from '../../images/affan.webp'
import ibtisam from '../../images/ibtisam.webp'
import farrukh from '../../images/Farrukh.webp'
import mustafa from '../../images/Mustafa.webp'
import teamElement from '../../images/teamElement.webp'
import Quote from '../../images/Quote.webp'
import lars from '../../images/lars.webp'
import berg from '../../images/berg.webp'
import raza from '../../images/raza.webp'
import carmelo from '../../images/carmello.webp'
import psii from '../../images/psincome.webp'
import phone from '../../images/phone.svg'
import send from '../../images/send.svg'

import WhatBackground from '../../images/WhatBackground.webp'
import blockchain from '../../images/blockchain.svg'
import webApp from '../../images/webApp.svg'
import smartContract from '../../images/smartContract.svg'
import mobileApp from '../../images/mobileApp.svg'
import bConsulting from '../../images/bConsulting.svg'
import ecom from '../../images/ecom.svg'
import availableEffect1 from '../../images/availableEffect1.png'

import { Section1 } from '../pages/section1';
import { Section2 } from '../pages/Section2';
import { Section3 } from '../pages/section3';
import { Section4 } from '../pages/Section4';
import { Section5 } from '../pages/Section5'
import { Section6 } from '../pages/Section6'
import { Section7 } from '../pages/Section7'
import { Section9 } from '../pages/Section9'
import { Section11 } from '../pages/Section11'
import { Section13 } from '../pages/Section13'
import { Section14 } from '../pages/Section14'
import { Section15 } from '../pages/Section15'
import { Section16 } from '../pages/Section16'
import { Section18 } from '../pages/Section18'
import { Section19 } from '../pages/Section19'
import { Section20 } from '../pages/Section20'
import { Section21 } from '../pages/Section21'
import { Section22 } from '../pages/Section22'
import { Section23 } from '../pages/Section23'
import { Section24 } from '../pages/Section24'
import { Section25 } from '../pages/Section25'
import { Section29 } from '../pages/section29';
import { GetInTouch } from '../pages/getInTouch';
import { Footer2 } from '../pages/footer2';
import { Section27 } from '../pages/Section27'



export const DefiPlatformDeveloment = ({handleShow,darkMode,handleDarkMode}) => {
  var [selectCustomer,setSelectCustomer] =useState({})
  
  const handleCustomer = (item) => {
    console.log('====================================');
    console.log(item);
    console.log('====================================');
    setSelectCustomer(item)
   }
  
  useEffect(()=>{
    animate()
    setSelectCustomer(teamData[1])
       },[])

       const teamData = [
        {
          name:"Carmelo Ippolito",
          designation:"Apes Planet",
          description:" “The most reliable team I've worked with, they always find a solution, are fast and professional. Excellent experience.” ",
          profile:carmelo,
        },
        {
          name:"Raza",
          designation:"Trustvibes",
          description:" “It was a great experience working with Dappomatics Team. These guys were thorough professionals and very passionate about the value they bring to the table. We had an amazing response time which is extremely important in the blockchain space.” ",
          profile:raza,
        },
        {
          name:"Lars Fabricus",
          designation:"CEO & FOUNDER of Roburna Blockchain",
          description:" “Dappomatics team is responsble for creating our roburna blockchain, and I must say they are dedicated to their work. Highly experienced team with strong communication skills” ",
          profile:lars,
        },
        {
          name:"Bjorn Mattens",
          designation:"Passive Income",
          description:" “We are amazed and grateful to the dappomatics team for handling our developement tasks with so ease and perfection. Overall a great software development company.” ",
          profile:psii,
        },
        {
          name:"Hassan Shahid",
          designation:"CEO of Berg Media",
          description:" “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
          profile:berg,
        }
      ];
      const settingsCustomer = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
         
        ]
      };
  return (
    <>
        {/* new sections */}
  <div className={darkMode?"defiPlatDevPage darkMode overflow-hidden":"defiPlatDevPage bg-white lightMode overflow-hidden"} >
        <header className='header fixed-top mainNavbar darkNav' id="navbar">
            <div className="container">
            <Navbar handleShow ={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} />
            </div>
        </header>

        <Section1 
        heading={'DeFi Platform Development'} 
        desc={'Our NFT development company helps you to create your NFT platform with robust technology.'} 
        image={defiPlatformBanner} 
        />
        <Section1 
        heading={'All New DeFi Development'} 
        desc={'Our DeFi development company helps you to create your DeFi platform with robust technology.'} 
        image={defiPlatformBanner2} 
        />
        <Section2 />
        <Section3 />
        <Section4 />
         {/* special service section */}
        <Section5 darkMode={darkMode} />
        <Section6 />
        <Section7 />
        <section className='customerSec'>
          <div className="grad"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className='col1'>
                  <img className='ellipse' src={Ellipse} alt="Ellipse" width={340} height={340} />
                  <h2>What our customers say</h2>
                  <div className="customerCard animatable fadeInUp ">
                    <p className='desc '>{selectCustomer?.description}</p>
                    <div className="profileDiv">
                      <img src={selectCustomer.profile} alt="customer" width={56} height={56} />
                      <div>
                        <p className="name">{selectCustomer?.name}</p>
                        <p className="designation">{selectCustomer?.designation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className='text-center'>
                  <div className="quoteImg">
                    <img src={Quote} alt="Quote" width={90} height={77.7} />
                  </div>
                  <div className="cutomersImg">
                  
                    <Slider {...settingsCustomer}>
                      {teamData.map((item,id)=>(
                      <div key={id} className="py-4">
                        <div className={selectCustomer?.name == item.name ? "imgDiv scale1-4" : "imgDiv" } onClick={()=>handleCustomer(item)}>
                          <img src={item.profile} alt="customers" width={100} height={100} />
                        </div>
                      </div>
                      ))}
                  
                    </Slider>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Section9 />
        {/* what we do */}
        <div className="container">
          <section className='whatWeDo'>
            <div className='grad'></div>
            <div className="row whatRow">
              <div className="col-xl-4 col-lg-3">
                <div className='whatWeDoMain'>
                  <h2>WHAT <br /> WE DO</h2>
                  <button className='btn getBtn' onClick={()=>handleShow("Get In Touch")} >Get In Touch</button>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <img className='WhatBackground' src={WhatBackground} alt="WhatBackground" width={565.6} height={428} />
                <div className="row whatWeDoRow">
                  <div className="col-md-3">
                    <div className="col1">
                      <div className="weDoCard animatable fadeInUp animationDelay200 mb-4">
                        <img src={blockchain} alt="blockchain" width={65.45} height={67.4} />
                        <p className="title">Blockchain Development</p>
                      </div>
                      <div className="weDoCard animatable fadeInUp  mb-4">
                        <img src={smartContract} alt="Smart_Contract" width={58.45} height={67.6} />
                        <p className="title">Smart Contracts Development</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="col2">
                      <div className="weDoCard animatable fadeInUp  mb-4">
                        <img src={smartContract} alt="smartContract" width={58.45} height={67.6} />
                        <p className="title">NFT Development</p>
                      </div>
                      <div className="weDoCard animatable fadeInUp animationDelay200">
                        <img src={mobileApp} alt="mobileApp" width={67.6} height={67.6} />
                        <p className="title">P2P Crypto Exchange</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-3">
                    <div className="col3">
                      <div className="weDoCard animatable fadeInUp animationDelay200 mb-4">
                        <img src={bConsulting} alt="Blockchain_Consulting" width={67.6} height={67.6} />
                        <p className="title">Blockchain Consulting</p>
                      </div>
                      <div className="weDoCard animatable fadeInUp animationDelay400">
                        <img src={blockchain} alt="Decentralized_Applications" width={65.6} height={67.4} />
                        <p className="title">Decentralized Applications (DAPP's)</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="col2">
                      <div className="weDoCard animatable fadeInUp animationDelay200 mb-4">
                        <img src={mobileApp} alt="mobileApp" width={67.6} height={67.6} />
                        <p className="title">Mobile Apps</p>
                      </div>
                      <div className="weDoCard animatable fadeInUp animationDelay400">
                        <img src={webApp} alt="webApp" width={72.16} height={69.7} />
                        <p className="title">Web Apps</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <Section11 />
        <section className='teamSec'>
          {darkMode && 
          <>
          <img className='effect' src={availableEffect1} alt="availableEffect1" />
          <img className='teamElement' src={teamElement} alt="team" width={320} height={751.26} />
          </>}
            <div className="container pr z-1">
              <div className="row">
                <div className="col-lg-7 order-2 order-lg-1 ">
                  <div className='imgMain'>
                    <div className='col1'>
                      <TeamMember name ="Ibtisam Ahmad" designation="Cheif Operating Officer" linkedIn="https://www.linkedin.com/in/ibtisam-ahmed-42447211a/" image={ibtisam} animation="animationDelay600" />
                      <TeamMember name ="Affan Khan" designation="VP Engineering"  linkedIn="https://www.linkedin.com/in/web3-engineer/" image={affan} />
                    </div>
                  
                    <div className='col2'>
                      <TeamMember name ="Ammar Khalid" designation="Tech Lead" fb="https://www.facebook.com/ammarkhalid237" linkedIn="https://www.linkedin.com/in/ammarkhalid123/" image={ammar} />
                      <TeamMember name ="Ahsan Iqbal" designation="Full Stack Developer" linkedIn="https://www.linkedin.com/in/ahsan-iqbal-b27212194/" image={ahsan} animation="animationDelay400" />
                    
                    </div>
                    <div className='col1'>
                      <TeamMember name ="Farrukh Kiani" designation="Business Development Head" linkedIn="https://www.linkedin.com/in/farrukhkiani/" image={farrukh} animation="animationDelay600" />
                      <TeamMember name ="Mustafa Ahmed" designation="Full Stack Developer" linkedIn="https://twitter.com/mustafaalvi21" image={mustafa} animation="animationDelay200" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-1 order-lg-2">
                  <div className='content'>
                    <h2>Team</h2>
                    <p>Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <Section13 />
        <Section14 />
        <Section15 />
        <Section16 />
        <Section18 />
        <Section19 />
        <Section20 />
        <Section21 />
        <Section22 />
        <Section23 />
        <Section24 />
        <Section27 />
        <Section25 />
        <GetInTouch handleShow={handleShow} />
        <Section29 />
    
        

       <Footer2 darkMode={darkMode} handleShow={handleShow} />
        
        <footer className='newFooter'>
            <div className="r1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 ">
                            <div className="logoDiv">
                                <img src={logoDark} alt="" />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                            <h4>Platform</h4>
                            <ul>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                            <h4>Explore</h4>
                            <ul>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                            <h4>Company</h4>
                            <ul>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                                <li><a href="">Wallet</a></li>
                                <li><a href="">NFT</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="subsRow">
                        <div className="row ai-center">
                            <div className="col-md-3">
                                <h3>Subscribe to our newsletter</h3>
                            </div>
                            <div className="col-md-6">
                            <form>
                                <div className="input-group">
                                    <input type="email" className="form-control border-white" placeholder="Your Email" value="" />
                                    <button className="btn btn-dark">Submit</button>
                                </div>
                            </form>
                            </div>
                            <div className="col-md-3">
                                <div className="reachOut">
                                    <p>Reach out at </p>
                                    <a href="mailto:contact@dappomatics.com">contact@dappomatics.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="r2">
                <div className="container">             
                    <div className="row ai-center">
                        <div className="col-md-3">
                            <p>© Copyright 2023</p>
                        </div>
                        <div className="col-md-6">
                            <div className="tos">
                                <a href="" className='mr-2'>Term of service</a>
                                <a href="">Privacy Policy</a>
                            </div>
                        </div>
                        <div className="col-md-3">
                        <div className="socialMain">
                            <a href="https://www.linkedin.com/company/dappomatics" target="_blank" > <i class="fab fa-2x fa-linkedin"></i></a>
                            <a href="https://www.facebook.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-facebook-square fb"></i></a>
                            <a href="https://twitter.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-twitter-square twitter"></i></a>
                            <a href="https://www.instagram.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-instagram git"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    </div>
    </>
  )
}

const TeamMember = ({name,designation,image,animation,fb ,linkedIn}) =>{
  return(
    <div className={`imgDiv animatable fadeInUp ${animation}`}>
    <img src={image} alt="team_member" width="100%" height="100%" />
    <div class="content1">
      <h3>{name}</h3>
      <p>{designation}</p>
      <div>
        <a href={linkedIn} target='_blank' rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
      </div>
    </div>
  </div>
  )
}


