import React from 'react'
import blockchain from '../../images/blockchain.svg'
import webApp from '../../images/webApp.svg'
import smartContract from '../../images/smartContract.svg'
import mobileApp from '../../images/mobileApp.svg'
import bConsulting from '../../images/bConsulting.svg'

export const Section10 = ({handleShow}) => {
  return (
    <div className="container" id='sec10'>
        <section className='coreServiceSec'>
                    <h2>Core Services</h2>
            <div className="row whatRow">
                <div className="col-xl-4 col-lg-5">
                <div className='whatWeDoMain'>
                    <h1>Core Services</h1>
                    <p className='desc'>Our DeFi development company helps you to create your DeFi platform with robust technology.</p>
                    <div className="btnMain">
                        <button className='btnGradient py-2 mr-3' onClick={()=>handleShow("Get In Touch")}>Explore</button>
                        <button className='btnGradientoutline py-2'>Create</button>
                    </div>
                </div>
                </div>
                <div className="col-xl-8 col-lg-7">
                <div className="row whatWeDoRow">
                    {/* <div className="col-md-3">
                    <div className="col1">
                        <div className="weDoCard animatable fadeInUp animationDelay200 mb-4">
                            <img src={blockchain} alt="blockchain" width={65.45} height={67.4} />
                            <p className="title">Blockchain Development</p>
                        </div>
                        <div className="weDoCard animatable fadeInUp  mb-4">
                            <img src={smartContract} alt="Smart_Contract" width={58.45} height={67.6} />
                            <p className="title">Smart Contracts Development</p>
                        </div>
                    </div>
                    </div> */}
                    <div className="col-xl-3 col-md-4">
                    <div className="col2">
                        <div className="whatWeOuterCard mb-4">
                            <div className="weDoCard animatable fadeInUp  ">
                                <img src={smartContract} alt="smartContract" width={58.45} height={67.6} />
                                <p className="title">NFT Development</p>
                            </div>
                        </div>
                        <div className="whatWeOuterCard">
                            <div className="weDoCard animatable fadeInUp animationDelay200">
                                <img src={mobileApp} alt="mobileApp" width={67.6} height={67.6} />
                                <p className="title">P2P Crypto Exchange</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="col-xl-3 col-md-4">
                        <div className="col3">
                            <div className="whatWeOuterCard mb-4">
                                <div className="weDoCard animatable fadeInUp animationDelay200 ">
                                    <img src={bConsulting} alt="Blockchain_Consulting" width={67.6} height={67.6} />
                                    <p className="title">Blockchain Consulting</p>
                                </div>
                            </div>
                            <div className="whatWeOuterCard">
                                <div className="weDoCard animatable fadeInUp animationDelay400">
                                    <img src={blockchain} alt="Decentralized_Applications" width={65.6} height={67.4} />
                                    <p className="title">Decentralized Applications (DAPP's)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-4">
                        <div className="col2">
                            <div className="whatWeOuterCard mb-4">
                                <div className="weDoCard animatable fadeInUp animationDelay200 ">
                                    <img src={mobileApp} alt="mobileApp" width={67.6} height={67.6} />
                                    <p className="title">Mobile Apps</p>
                                </div>
                            </div>
                            <div className="whatWeOuterCard">
                                <div className="weDoCard animatable fadeInUp animationDelay400">
                                    <img src={webApp} alt="webApp" width={72.16} height={69.7} />
                                    <p className="title">Web Apps</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </section>
    </div>
  )
}
