import React from 'react'

export const Section9 = () => {
  return (
    <section className='secFive' id='sec9'>
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-6">
                    <h1 className=' animatable fadeInUp '>DeFi <br /> Development Company</h1>
                    <p className=' animatable fadeInUp '>Our NFT development company helps you to create your NFT platform with robust technology.</p>  
                    <div className="btnMain animatable fadeInUp ">
                        <button className='btnGradient py-2 px-5 mr-3'>Explore</button>
                        <button className='btnGradientoutline py-2 px-5'>Create</button>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="imgDiv">
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
