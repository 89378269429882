import React from 'react'
import nftLeft from '../../images/nftLeft.png'
import nftRight from '../../images/nftRight.png'

export const Section22 = () => {
  return (
    <section className='secEleven' id='sec22'>
    <div className="row1">
        <div className="bgEffect"></div>
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-6">
                    <div className="imgDiv1 animatable bounceInLeft animationDelay100">
                        <img className='img-fluid' src={nftLeft} alt="nft" />
                    </div>
                </div>
                <div className="col-md-6 colorCol">
                    <div className="content">
                        <h2 className='animatable fadeInUp '>NFT Launchpad Development</h2>
                        <p className='animatable fadeInUp '>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s ,when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="row2">
        <div className="bgEffect"></div>
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-6 order-md-1 order-2 colorCol">
                    <div className="content">
                        <h2 className='animatable fadeInUp '>NFT Launchpad Development</h2>
                        <p className='animatable fadeInUp '>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s ,when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
                <div className="col-md-6 order-md-2 order-1 ">
                    <div className="imgDiv2 animatable bounceInRight">
                        <img className='img-fluid' src={nftRight} alt="nft" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
