import React from 'react'
import effect1 from '../../images/effect1.png'

export const Section14 = () => {
  return (
    
    <section className='secOne pb-0' id='sec14'>
    <img src={effect1} alt="effect1" className="effect" />

    <div className="container pr">
        <div className="row ai-center">
            <div className="col-md-6">
                <h1 className='animatable fadeInUp '>Build Web 3 Apps,Easily.</h1>
            </div>
            <div className="col-md-6">
                <p className='animatable fadeInUp animatable fadeInUp animationDelay100'>Our DeFi development company helps you to create your DeFi platform with robust technology.</p>  
                <div className="btnMain animatable fadeInUp animationDelay200">
                    <button className='btnGradient py-2 px-5 mr-3'>Explore</button>
                    <button className='btnGradientoutline py-2 px-5'>Create</button>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
