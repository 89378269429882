import React from 'react'
import { useState } from 'react';
import axios from "axios";
import { ToastProvider, useToasts } from 'react-toast-notifications';

export const Section17 = () => {
    const { addToast } = useToasts();
    const [newLetterMail, setnewLetterMail] = useState("");


    const submitNewsForm =(e) => {
        e.preventDefault();
        console.log(newLetterMail);
        if(!newLetterMail){
            return addToast("email is required", { appearance: 'warning',autoDismiss: true, });
        }
        const response = axios.post(`${process.env.REACT_APP_BASEURL}/subscribe_news_letter`, {email:newLetterMail})
        .then((res)=>{
            console.log(res);
            setnewLetterMail("")
            addToast("Thanks for subscribe", { appearance: 'warning',autoDismiss: true, });
        })
        .catch((err)=>{
            console.log(err);
            if(err?.response?.data?.message == "Email already subscribed"){
                addToast(err?.response?.data?.message, { appearance: 'success',autoDismiss: true, });
            }else{
                addToast("Something went wrong", { appearance: 'warning',autoDismiss: true, });
            }
        })
      }
  return (
    <div className="newsletterSec" id='sec17'>
        <div className="container">
            <div className="row">
                <div className="col-md-6">         
                    <div className="col1">
                        <div className="head animatable fadeInUp ">Sign up for our newsletter</div>
                        <div className="desc animatable fadeInUp animationDelay200">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="col2">
                        <input onChange={(e)=>setnewLetterMail(e.target.value)} value={newLetterMail} type="text"placeholder='Enter your email' />
                        <button onClick={submitNewsForm}>Sign up </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
