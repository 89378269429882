import logo from '../images/Logo.webp'
import logoDark from '../images/logoDark.png'
import arrowRight from '../images/arrowRight.svg'
import {NavLink} from 'react-router-dom'
import {Switch as SwitchMode} from '@mui/material';
import { HashLink } from 'react-router-hash-link';

export const Navbar = ({handleShow,darkMode,handleDarkMode}) => {
    return(
        <nav className="navbar navbar-expand-lg navbar-light py-4  a1 row mx-0  navFont pr-0"  >
          <div className="text-center ">
            <NavLink className="navbar-brand p-0" style={{marginTop: '-6px'}} to="/">
              {darkMode ?
                <img className="img-fluid" id="logo" src={logoDark} alt="Logo" width={160} height={37.8} /> 
                :
                <img className="img-fluid" id="logo" src={logo} alt="Logo" width={160} height={37.8} /> 
               }
              </NavLink>
          </div>
          <div className="order-md-2 order-3 toggle-icon-custom">
          <button className="navbar-toggler btn-btn-toggle" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            </div>
          <div className="collapse navbar-collapse col  jc-end collapse-nav"  id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <NavLink className="nav-item nav-link" exact to="/">Home </NavLink>
                <NavLink className="nav-item nav-link" exact to="/about">About Us</NavLink>
                <a className="nav-item nav-link" href="/blog">Blog</a>
                <NavLink className="nav-item nav-link" exact to='/contact' >Contact</NavLink>
                {/* <NavLink className="nav-item nav-link hoverDrop" exact to='/Defi-Platform-Develoment' >
                  Pages
                  <div className="drop">
                    <ul>
                      <li><HashLink to="#sec1">Section1</HashLink></li>
                      <li><HashLink to="#sec2">Section2</HashLink></li>
                      <li><HashLink to="#sec3">Section3</HashLink></li>
                      <li><HashLink to="#sec4">Section4</HashLink></li>
                      <li><HashLink to="#sec5">Section5</HashLink></li>
                      <li><HashLink to="#sec6">Section6</HashLink></li>
                      <li><HashLink to="#sec7">Section7</HashLink></li>
                      <li><HashLink to="#sec8">Section8</HashLink></li>
                      <li><HashLink to="#sec9">Section9</HashLink></li>
                      <li><HashLink to="#sec10">Section10</HashLink></li>
                      <li><HashLink to="#sec11">Section11</HashLink></li>
                      <li><HashLink to="#sec12">Section12</HashLink></li>
                      <li><HashLink to="#sec13">Section13</HashLink></li>
                      <li><HashLink to="#sec14">Section14</HashLink></li>
                      <li><HashLink to="#sec15">Section15</HashLink></li>
                      <li><HashLink to="#sec16">Section16</HashLink></li>
                      <li><HashLink to="#sec17">Section17</HashLink></li>
                      <li><HashLink to="#sec18">Section18</HashLink></li>
                      <li><HashLink to="#sec19">Section19</HashLink></li>
                      <li><HashLink to="#sec20">Section20</HashLink></li>
                      <li><HashLink to="#sec21">Section21</HashLink></li>
                      <li><HashLink to="#sec22">Section22</HashLink></li>
                      <li><HashLink to="#sec23">Section23</HashLink></li>
                      <li><HashLink to="#sec24">Section24</HashLink></li>
                      <li><HashLink to="#sec25">Section25</HashLink></li>
                      <li><HashLink to="#sec26">Section26</HashLink></li>
                      <li><HashLink to="#sec27">Section27</HashLink></li>
                      <li><HashLink to="#sec28">Section28</HashLink></li>
                    </ul>
                  </div>
                </NavLink> */}
              
              </div>
              <div className="darkModeBtn">
                <SwitchMode
                  checked={darkMode}
                  onChange={handleDarkMode}
                  inputProps={{ 'aria-label': 'controlled' }}
                  />
              </div>
              <button className="sigupBtn" onClick={()=>handleShow("Let's Connect")}>Let's Connect <img className='ml-1' src={arrowRight} alt="consult_now" width="13px" height="13px"/></button>
            
          </div>
          
          {/* <div className="col-md-3 col-5 text-center order-md-3 order-1 connect-wallet-small">
            <button className="btn color-white" style={{borderRadius: '10px', backgroundColor: '#42c6ff'}}>
              Sign up Now
            </button>
          </div> */}
        </nav>
    )
}