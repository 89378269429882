/* eslint-disable */

import $ from 'jquery';
  function animate() {
    try{

    // Function which adds the 'animated' class to any '.animatable' in view
    var doAnimations = function() {
    "use strict";
      
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
            $animatables.each(function(i) {
           var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
                }
        });
    
        };
      
      // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');

      var animatabl = $('.animatabl');
      animatabl.removeClass('animatabl').addClass('animatedShip');

          // when to show scroll top button
  window.addEventListener('scroll', () => {
    const scrollable = document.documentElement.scrollHeight - window.innerHeight; //total no of line
    const scrolled = window.scrollY; //specific no of line where we have ,when scoll it increase
    // console.log(Math.ceil(scrolled))
    // if (Math.ceil(scrolled) > 548) {
      
    //     document.getElementById('scrollToTop').style.display = 'inline';
    // } else {
    //     document.getElementById('scrollToTop').style.display = 'none';
    // }
    if (Math.ceil(scrolled) > 248) {
      
        document.getElementById('navbar').classList.add("bgColored")
    } else {
        document.getElementById('navbar').classList.remove("bgColored")
    }
});
      
      $("#scrollToTop").on("click", function() {
        window.scrollTo(0, 0);
    });

    $(window).on("load resize scroll", function() {
      $(".frame1").each(function() {
        var windowTop = $(window).scrollTop();
        var elementTop = $(this).offset().top;
        var leftPosition = windowTop - elementTop;
        if(leftPosition > -700 && leftPosition <=0){
          console.log(leftPosition);

          $(this)
            .find(".frame3")
            .css({ right: leftPosition });
        }
      });
    });
    }catch(err){
      console.log(err);
    }
      
  }

  export {animate}
  