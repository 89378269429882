import React from 'react'
import { useEffect, useState } from 'react'

import effect1 from '../../images/effect1.png'
import Angular from '../../images/Angular.svg'
import Android from '../../images/Android.svg'
import ReactNative from '../../images/ReactNative.svg'
import solidity from '../../images/solidity.svg'
import iOS from '../../images/iOS.svg'
import GO from '../../images/GO.svg'
import PHP from '../../images/PHP.svg'
import noSQL from '../../images/noSQL.svg'
import Oracle from '../../images/Oracle.svg'
import MySQL from '../../images/MySQL.svg'
import metamask from '../../images/metamask.png'
import mongo from '../../images/mongo.png'
import nodejsLogo from '../../images/nodejsLogo.webp'
import polygon from '../../images/polygon.png'
import blockchainLogo from '../../images/blockchain.webp'

export const Section26 = () => {
    var [techItem,setTechItem] =useState([])
    var [currentBtn,setCurrentBtn] =useState('Popular')
    const handleTech =(tech) =>{
        if(tech == "Popular"){
            setTechItem(techData.popular)
            setCurrentBtn(tech)
        }
        else if(tech == "Blockchain"){
            setTechItem(techData.blockchain)
            setCurrentBtn(tech)
        }
        else if(tech == "Frontend"){
            setTechItem(techData.frontend)
            setCurrentBtn(tech)
        }
        else if(tech == "Backend"){
            setTechItem(techData.backend)
            setCurrentBtn(tech)
        }
        else if(tech == "Mobile"){
            setTechItem(techData.mobile)
            setCurrentBtn(tech)
        }
        else if(tech == "Database"){
            setTechItem(techData.database)
            setCurrentBtn(tech)
        }
    }
    

  const techData = {
      popular:[
          {
              name:"solidity",
              logo:solidity,
          },
          {
              name:"metamask",
              logo:metamask,
          },
          {
              name:"blockchain",
              logo:blockchainLogo,
          },
          {
              name:"React",
              logo:ReactNative,
          }
      ],
      blockchain:[
          {
              name:"solidity",
              logo:solidity,
          },
          {
              name:"metamask",
              logo:metamask,
          },
          {
              name:"blockchain",
              logo:blockchainLogo,
          },
          {
              name:"polygon",
              logo:polygon,
          },
      ],
      frontend:[
          {
              name:"React",
              logo:ReactNative,
          },
      ],
      backend:[
          {
              name:"nodejsLogo",
              logo:nodejsLogo,
          },
          {
              name:"PHP",
              logo:PHP,
          },
          {
              name:"GO",
              logo:GO,
          },
      ],
      mobile:[
          {
              name:"React Native",
              logo:ReactNative,
          },
          {
              name:"Android",
              logo:Android,
          },
          {
              name:"iOS",
              logo:iOS,
          },
      ],
      database:[
          {
              name:"mongo",
              logo:mongo,
          },
          {
              name:"MySQL",
              logo:MySQL,
          },
          {
              name:"noSQL",
              logo:noSQL,
          },
          {
              name:"Oracle",
              logo:Oracle,
          },
      ],
  }
  useEffect(()=>{
    setTechItem(techData.popular)
   },[])

  return (
    <section className='connectWeb3Sec' id='sec26'>
        <img src={effect1} alt="effect1" className="effect1" />

        <div className="container pr">
            <h1>Dappomatics's set of technologies for software development</h1>
            <div className="title animatable fadeInUp ">Benefit from highly experienced specialists in desktop, web, and mobile development. As one of the leading software development companies, we employ the latest technologies, frameworks, and components to create solutions that combine a strong technical back-end with easy to use modern design.</div>
            <div className="lang">
                <div className={currentBtn == "Popular" ?"name active" :"name   " } onClick={()=>handleTech("Popular")}>Popular</div>
                <div className={currentBtn == "Blockchain" ?"name active":"name  "}  onClick={()=>handleTech("Blockchain")}>Blockchain</div>
                <div className={currentBtn == "Frontend" ?"name active":"name  "}  onClick={()=>handleTech("Frontend")}>Frontend</div>
                <div className={currentBtn == "Backend" ?"name active":"name  "}  onClick={()=>handleTech("Backend")}>Backend</div>
                <div className={currentBtn == "Mobile" ?"name active":"name  "}  onClick={()=>handleTech("Mobile")}>Mobile</div>
                <div className={currentBtn == "Database" ?"name active":"name  "}  onClick={()=>handleTech("Database")}>Database</div>
            </div>
            <div className="row">
                {techItem.map((item,key)=>(
                <div className="col-xl-3 col-md-4 col-6 ">
                    <TechCard name={item.name} logo={item.logo} />
                </div>
                ))}
                
            </div>
        
        </div>
    </section>
  )
}

const TechCard = ({name,logo}) => {
    return(
        <div className="techCard">
            <div className="logoDiv">
                <img 
                className={name == "solidity" || name == "blockchain" || name == "iOS" || name == "noSQL" ? "dInvert":""}
                src={logo} alt="" />
            </div>
            <div className="name">{name}</div>
        </div>
    )
}