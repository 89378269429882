import React from 'react'
import effect1 from '../../images/effect1.png'

import ourP1 from '../../images/ourP1.png'
import ourP2 from '../../images/ourP2.png'
import ourP3 from '../../images/ourP3.png'
import ourP4 from '../../images/ourP4.png'
import ourP5 from '../../images/ourP5.png'

export const Section11 = () => {
  return (
    <section className='secSix' id='sec11'>
        <img src={effect1} alt="effect1" className="effect" />
        <div className="container pr">
            <h1>Our Project</h1>
            <div className="row">
               
                <div className=" col-md-4">
                    <div className="projectCard mb-3 animatable fadeInUp animationDelay200">
                        <div className="imgDiv">
                            <img className="img-fluid" src={ourP1} alt="" />
                        </div>
                        <p>Decentralized Exchanges</p>
                    </div>
                    <div className="projectCard mb-3 animatable fadeInUp animationDelay400">
                        <div className="imgDiv">
                            <img className="img-fluid" src={ourP2} alt="" />
                        </div>
                        <p>DAOVentures</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="projectCardLg animatable fadeInUp ">
                        <div className="imgDiv">
                            <img className="img-fluid" src={ourP3} alt="" />
                        </div>
                        <p>Decentralized Lending</p>
                    </div>
                </div>
                <div className=" col-md-4">
                    <div className="projectCard mb-3 animatable fadeInUp animationDelay600">
                        <div className="imgDiv">
                            <img className="img-fluid" src={ourP4} alt="" />
                        </div>
                        <p>Collateralized Borrowing</p>
                    </div>
                    <div className="projectCard mb-3 animatable fadeInUp animationDelay800">
                        <div className="imgDiv">
                            <img className="img-fluid" src={ourP5} alt="" />
                        </div>
                        <p>Pickle Finance</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
