import React from 'react'
import artistBanner1 from '../../images/artistBanner1.png'
import artistBanner2 from '../../images/artistBanner2.png'
import artistBanner3 from '../../images/artistBanner3.png'
import artistBanner4 from '../../images/artistBanner4.png'
import artistLogo1 from '../../images/artistLogo1.png'
import artistLogo2 from '../../images/artistLogo2.png'
import artistLogo3 from '../../images/artistLogo3.png'
import artistLogo4 from '../../images/artistLogo4.png'

export const Section7 = () => {
  return (
    
    <section className='bestArtistSec' id='sec7'>
        <div className="container">
            <h1 className=''>Best Artists</h1>
            <div className="row">
                <div className="col-lg-3 col-sm-6 mb-3 animatable fadeInUp ">
                    <BestArtistCard image={artistBanner1} logo={artistLogo1} text={"NFTs Artis from california I hope you like it."} />
                </div>
                <div className="col-lg-3 col-sm-6 mb-3 animatable fadeInUp animationDelay200">
                    <BestArtistCard image={artistBanner2} logo={artistLogo2} text={"NFTs Artis from california I hope you like it."} />
                </div>
                <div className="col-lg-3 col-sm-6 mb-3 animatable fadeInUp animationDelay400">
                    <BestArtistCard image={artistBanner3} logo={artistLogo3} text={"NFTs Artis from california I hope you like it."} />
                </div>
                <div className="col-lg-3 col-sm-6 mb-3 animatable fadeInUp animationDelay600">
                    <BestArtistCard image={artistBanner3} logo={artistLogo4} text={"NFTs Artis from california I hope you like it."} />
                </div>
            </div>
        </div>
    </section>
  )
}

const BestArtistCard = ({image,logo,text}) =>{
    return(
        <div className="bestCard">
            <div className="bannerDiv">
                <img className='img-fluid w-100' src={image} alt="" />
            </div>
            <div className="content">
                <div className="logoDiv">
                    <img className='img-fluid' src={logo} alt="" />
                </div>
                <div className="desc">{text}</div>
                <button className="btnGradient px-5 py-1">Follow</button>
            </div>
        </div>
    )
}