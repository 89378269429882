import React from 'react'
import effect1 from '../../images/effect1.png'
import web3Banner from '../../images/web3Banner.jpg'

export const Section18 = () => {
  return (
    
    <section className='connectWeb3Sec' id='sec18'>
        <img src={effect1} alt="effect1" className="effect1" />

        <div className="container pr">
            <h1>Connect to Web 3 Easily</h1>
            <div className="title animatable fadeInUp ">Lorem Ipsum is simply dummy text of the printing an type setting industry.</div>
            <div className="lang">
                <div className="name animatable fadeInUp ">JavaScript</div>
                <div className="name animatable fadeInUp animationDelay200">Python</div>
                <div className="name animatable fadeInUp animationDelay400">React</div>
                <div className="name animatable fadeInUp animationDelay600">Go</div>
            </div>
            <div className="bannerImg">
                <img className='img-fluid' src={web3Banner} alt="" />
            </div>
            <div className="btnMain animatable fadeInUp ">
                <button className='btnGradient'>Try it on Replit</button>
                <button className='btnGradientoutline'>Explore Documentation</button>
            </div>
        </div>
    </section>
  )
}
