import phone from '../images/phone.svg'
import send from '../images/send.svg'
import { Navbar } from './Navbar'


export const Contact = ({handleShow,darkMode,handleDarkMode}) => {
    const handleShowFromConatact =() => {

    }
    return(
        <div className={darkMode?" darkMode overflow-hidden":" lightMode overflow-hidden"}>
        <header className='header fixed-top mainNavbar'>
            <div className="container">
            <Navbar handleShow ={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} />
            </div>
        </header>
        <div className="contactPage pt-86">
         <div className="heading">
             <div className="container">
                 <div className="mx-0 row jc-center">
                     <div className="col-lg-10">

                        <h1>Contact</h1>
                     </div>
                 </div>
             </div>
          </div>
          
          <div className="mainContent">
              <div className="container">
                <div className="row mx-0 jc-center">
                <div className="col-md-6 col-lg-5">
                    <div className='mb-5'>
                    <p className="contact">
                    You’ve any question? <br />
                    feel free to contact with us.
                    </p>
                    <div className='contentDiv'>
                        <div className="imgDiv">
                        <img src={phone} alt="phone" />
                        </div>
                        <div className='contactDiv'>
                        <p>Call us for imiditate support on this number</p>
                        <a href="tel:+92 335 2491559"  > +92 335 2491559</a>
                        </div>
                    </div>
                    <div className='contentDiv'>
                        <div className="imgDiv">
                        <img src={send} alt="phone" />
                        </div>
                        <div className='contactDiv'>
                        <p>Send us email for any kind of inquiry</p>
                        <a href="mailTo:dappomatics@gmail.com" >contact@dappomatics.com</a>
                        </div>
                    </div>
                    <div className="socialMain">
                      <a href="https://www.linkedin.com/company/dappomatics" target="_blank" rel="noopener noreferrer"> <i class="fab fa-2x fa-linkedin"></i></a>
                      <a href="https://www.facebook.com/dappomatics" target="_blank" rel="noopener noreferrer"> <i class="fab fa-2x fa-facebook-square fb"></i></a>
                      <a href="https://twitter.com/dappomatics" target="_blank" rel="noopener noreferrer"> <i class="fab fa-2x fa-twitter-square twitter"></i></a>
                      <a href="https://www.instagram.com/dappomatics" target="_blank" rel="noopener noreferrer"> <i class="fab fa-2x fa-instagram git"></i></a>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5">
                <form>
                    <div className="form-group">
                    {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                    <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Name*" />
                    </div>
                    <div className="form-group">
                    {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your email address*" />
                    </div>
                    <div className='d-flex'>
                    <div className="form-group mr-3 w-100">
                        <input type="text" className="form-control" id="exampleInputtext1" placeholder="Skype or Phone" />
                    </div>
                    <div className="form-group w-100">
                        <input type="text" className="form-control" id="exampleInputtext1" placeholder="Subject*" />
                    </div>
                
                    </div>
                    <div className="form-group">
                    <textarea type="text" className="form-control" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
                    </div>
                
                    <button type="submit" className="btn btn-primary sendBtn">Send Message</button>
                </form>
                </div>
                </div>
              </div>
          </div>
        </div>
        </div>
    )
}