import React from 'react'
import nftArt from '../../images/nftArt.png'
import effect1 from '../../images/effect1.png'

export const Section20 = () => {

  return (
    <div>



        <section className='secTen secOne' >
            <img src={effect1} alt="effect1" className="effect" />
            <div className="container pr">
                <h1 className='text-center'>Our NFT EcoSystem</h1>
                <div className="row ai-center">
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img src={nftArt} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content">
                            <p className=' animatable fadeInUp '>Our DeFi development company helps you to create your DeFi platform with robust technology.</p>  
                            <div className="exprerience">
                                <h3 className='animatable fadeInUp '>7 years+</h3>
                                <h4 className='animatable fadeInUp animationDelay100'>Experience</h4>
                            </div>
                            {/* <div className="pMain">
                                <div className="projects animatable fadeInUp animationDelay200">
                                    <h5>100<span>K+</span></h5>
                                    <p>Active NFTs</p>
                                </div>
                                <div className="projects animatable fadeInUp animationDelay400">
                                    <h5>100<span>+</span></h5>
                                    <p>Active Projects</p>
                                </div>
                            </div> */}
                            <div className="btnMain mt-4 animatable fadeInUp ">
                                <button className='btnGradient py-2 px-5 mr-3'>Explore</button>
                                <button className='btnGradientoutline py-2 px-5'>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
