import React from 'react'
import prebuiltIco from '../../images/faq.svg'
import ovalEffect from '../../images/ovalEffect.png'

export const Section29 = () => {
  return (
    <section className='sec29' id='sec16'>
    <img src={ovalEffect} alt="effect1" className="effect" />
    <div className="container pr">
        <h1>Get the facts</h1>
        <div className="descrip">Here are some of the roomsy frequently asked questions</div>
        <div className="row">
            <div className="col-md-6">
                <div className="eightCard">
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">What is the Categories of NFTs?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">How NFTS work?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">How to sell and Buy NFTS?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="eightCard">
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">What is the Categories of NFTs?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">How NFTS work?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className="row1">
                        <img src={prebuiltIco} alt="ico" />
                        <div className='animatable fadeInUp '>
                            <div className="head">How to sell and Buy NFTS?</div>
                            <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
  )
}
