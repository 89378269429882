import React, { useState,useEffect,useRef,lazy } from 'react';
import ReactDOM from 'react-dom'
import { Modal, Button } from "react-bootstrap"
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import './App.css'
import './assets/darkPage.css'
import '@fortawesome/fontawesome-free/css/all.css'

// import '../node_modules/jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './assets/animate.css';
import axios from "axios";


import { Home } from './component/home';
import phone from './images/phone.svg'
import send from './images/send.svg'
import { Contact } from './component/contact';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Navbar } from './component/Navbar';
import { About } from './component/aboutus';
import { NotFound } from './component/404';
import { DefiPlatformDeveloment } from './component/defiPlatformDeveloment/defiPlatformDeveloment';
import linkedinWhite from './images/linkedinWhite.svg'
import facebookWhite from './images/facebookWhite.svg'
import twitterfWhite from './images/twitterfWhite.svg'
import instaWhite from './images/instaWhite.svg'
import emailWhite from './images/emailWhite.svg'
import phoneWhite from './images/phoneWhite.svg'
import closeIco from './images/closeIco.svg'
import arrDown from './images/arrDown.svg'

function App() {
  const { addToast } = useToasts();

  const [connectModalText, setConnectModalText] = useState("");
  const [contactForm, setContactForm] = useState({   
    name:"",
    email:"",
    phone:"",
    subject:"",
    message:"",
});
  const [show, setShow] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [darkMode, setdarkMode] = useState(true);
  const [duration, setSuration] = useState(15);

  const handleClose = () => setShow(false);
  const handleShow = (text) => {
      setShow(true)
      setConnectModalText(text)
  }
  const handleCloseMeeting = () => setShowMeeting(false);
  const handleShowMeeting = (text) => {
      setShowMeeting(true)
  }
      // notification setting
      // const notify = (response,types) => 
      // types(response, {
      //    position: "bottom-right",
      //    autoClose: 5000,
      //    hideProgressBar: false,
      //    closeOnClick: true,
      //    pauseOnHover: true,
      //    draggable: true,
      //    progress: undefined,
      // });

  useEffect(() => {
    console.log('====================================');
    console.log(window.location.pathname);
    console.log("Defi-Platform-Develoment");
    console.log(window.location.pathname == "Defi-Platform-Develoment");
    console.log('====================================');
    if(window.location.pathname == "/" || window.location.pathname == "/contact" || window.location.pathname == "/about" || window.location.pathname == "/blog" || window.location.pathname == "/Defi-Platform-Develoment"){

    }else{
      window.location.href = "https://dappomatics.com/404";
    }
  }, [])

  const handleDarkMode=(e)=>{
    console.log("loading");
    if(darkMode){
        setdarkMode(false);
        document.body.style.backgroundColor="white"
        console.log(e.target);
    }else{
        setdarkMode(true)
        document.body.style.backgroundColor="black"
        console.log(e.target);
    }

  }

  const handleContactF =(e) => {
    setContactForm({...contactForm,[e.target.name]:e.target.value})
  }

  const submitContactForm =(e) => {
    e.preventDefault();
    console.log(contactForm);
    var valid = /^!*(\d!*){10,}$/;
    var validM = valid.test(contactForm.phone)
    if(contactForm.phone && contactForm.phone.includes(' ') ) return alert("Spaces are not allowed in phone number");
    if(contactForm.phone && (!validM || contactForm.phone.length < 10)) return alert("Phone must contain atleast 10 digits");
    
    if(!contactForm.email){
      return addToast('email is required', { appearance: 'warning',autoDismiss: true, });
    }
    if(!contactForm.message){
      return addToast('message is required', { appearance: 'warning',autoDismiss: true, });
    }
    const response = axios.post(`${process.env.REACT_APP_BASEURL}/contact-us-mailer`, contactForm)
    .then((res)=>{
      console.log(res);
        addToast('Thanks, we will reach you soon.', { appearance: 'warning',autoDismiss: true, });
        handleClose(false)
        setContactForm({   
            name:"",
            email:"",
            phone:"",
            subject:"",
            message:"",
        });
    })
    .catch((err)=>{
        console.log(err);
        addToast('Something went wrong', { appearance: 'error',autoDismiss: true, });
        // handleClose(false)
    })
}


  return (
  <>
    {/* <Home /> */}
    <Router>
         
      <Switch>
              <>
              <Route exact path="/contact"><Contact handleShow={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} /></Route>
              <Route exact path="/"><Home handleShow={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode}  /></Route>
              <Route exact path="/about"><About handleShow={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} /></Route>
              <Route exact path="/blog"><Redirect to="/blog" /></Route>
              <Route exact path="/Defi-Platform-Develoment"><DefiPlatformDeveloment handleShow={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} /> </Route>
              </>
      </Switch>
    </Router>

      <Modal show={show} onHide={handleClose} className='contactModal'>
      
      <Modal.Body>
        <div className="mainContent">
          <img src={closeIco} className="closeIco" alt="" onClick={handleClose} />
          {/* <i class="fa fa-times closeIco" ></i> */}
          <div className="row mx-0">
            <div className="col-md-5 col1">
              <div className=''>
                <h1>{connectModalText}</h1>
                <p className="contact">
                You’ve any question? <br />
                </p>
                <div className="feeFree">feel free to contact with us.</div>
                
                <div className="socialMain">
                  <a href="https://www.linkedin.com/company/dappomatics" target="_blank" ><img src={linkedinWhite} alt="" /> </a>
                  <a href="https://www.facebook.com/dappomatics" target="_blank" > <img src={facebookWhite} alt="" /></a>
                  <a href="https://twitter.com/dappomatics" target="_blank" > <img src={twitterfWhite} alt="" /></a>
                  <a href="https://www.instagram.com/dappomatics" target="_blank" > <img src={instaWhite} alt="" /></a>
                </div>
                <p className='callUs'>Call us for imiditate support on this number</p>
                <div className='contentDiv'>
                  <div className="imgDiv">
                    <img src={phoneWhite} alt="phone" />
                  </div>
                  <div className='contactDiv'>
                    <a href="tel:+ 92 335 2491559"  >+92 335 2491559</a>
                  </div>
                </div>
                <div className='contentDiv'>
                  <div className="imgDiv">
                    <img src={emailWhite} alt="email" />
                  </div>
                  <div className='contactDiv'>
                    {/* <p>Send us email for any kind of inquiry</p> */}
                    <a href="mailTo:contact@dappomatics.com" target="_blank" >contact@dappomatics.com</a>
                  </div>
                </div>
              
              </div>
            </div>
            <div className="col-md-7 col2">
            <form onSubmit={(e)=>submitContactForm(e)}>
              <div className="form-group">
                {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                <input onChange={(e)=>handleContactF(e)} name="name" type="text" className="form-control"  placeholder="Name*" />
              </div>
              <div className="form-group">
                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                <input onChange={(e)=>handleContactF(e)} name="email" type="email" className="form-control"  aria-describedby="emailHelp" placeholder="Your email address*" />
              </div>
              <div className='d-flex'>
                <div className="form-group mr-3 w-100">
                  <input onChange={(e)=>handleContactF(e)} name="phone" type="text" className="form-control" placeholder="Skype or Phone" />
                </div>
                <div className="form-group w-100">
                  <input onChange={(e)=>handleContactF(e)} name="subject" type="text" className="form-control" placeholder="Subject*" />
                </div>
            
              </div>
              <div className="form-group">
                <textarea onChange={(e)=>handleContactF(e)} name="message" type="text" className="form-control" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
              </div>
            
              <button type="submit" className="btnGradient py-2 px-5 ">Send Message</button>
            </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    
    </Modal>

      <Modal show={showMeeting} onHide={handleCloseMeeting} className='contactModal'>
      
      <Modal.Body>
        <div className="mainContent">
          <div className="reqMeetingMain">
            <img src={closeIco} className="closeIco" alt="" onClick={handleCloseMeeting} />
            {/* <i class="fa fa-times closeIco" ></i> */}
            <h1>Request a Meeting</h1>
            <form>
                <div className="form-group">
                  <label className='ml-3' >Select Topic</label>
                    <div className="inputDiv">
                      <select name="" id="" className="form-control round-30">
                        <option value="">Please select topic</option>
                        <option value="">Blockchain Consulting</option>
                        <option value="">NFT Development</option>
                        <option value="">P2P Crypto Exchange</option>
                        <option value="">Decentralized Applications (DAPP's)</option>
                        <option value="">Web Apps</option>
                        <option value="">Mobile Apps</option>
                      </select>
                      <img className='arrDown' src={arrDown} alt="" />
                  </div>
                </div>
              
                <label className='ml-3'>Select Duration</label>
                <div className='selectTopic'>
                  <div className={duration == 15?"sBtn active":"sBtn "} onClick={()=>setSuration(15)}>15 min</div>
                  <div className={duration == 30?"sBtn active":"sBtn "} onClick={()=>setSuration(30)}>30 min</div>
                  <div className={duration == 60?"sBtn active":"sBtn "} onClick={()=>setSuration(60)}>60 min</div>
                </div>

                <div className="dateM">
                  <div className="form-group mr-2">
                    <label className='ml-3' >Day</label>
                      <input type="date" className="form-control"  placeholder="Name*" />
                    </div>
                  <div className="form-group mr-2">
                    <label className='ml-3' >Time-from</label>
                    <div className="inputDiv">
                      <select name="" id="" className="form-control round-30">
                        <option value="">8:00 AM</option>
                      </select>
                      <img className='arrDown' src={arrDown} alt="" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='ml-3' >Time-To</label>
                    <div className="inputDiv">
                      <select name="" id="" className="form-control round-30">
                        <option value="">8:15 AM</option>
                      </select>
                      <img className='arrDown' src={arrDown} alt="" />
                    </div>
                  </div>
                </div>
                
                <div className="btnMain">
                  <button type="submit" className="btnGradient py-2  mr-3">Request</button>
                  <button className="btnGradientoutline py-2" onClick={handleCloseMeeting}>Cancel</button>

                </div>
              </form>
          </div>
        </div>
      </Modal.Body>
    
    </Modal>

    {/* <button class="" id="scrollToTop"><i class="fas fa-arrow-up"></i></button> */}
    {/* <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>       */}

    </>
    
  );
}


export {App};
