import React from 'react'
import nftImg from '../../images/nftImg.png'
import ovalEffect from '../../images/ovalEffect.png'

export const Section21 = () => {
  return (
    
    <section className='secThree' id='sec21'>
        <img src={ovalEffect} alt="effect1" className="effect" />
        <div className="container pr">
            <div className="row ai-center">
                <div className="col-md-5">
                    <div className="imgDiv">
                        <img className='img-fluid' src={nftImg} alt="DEFI" />
                    </div>
                </div>
                <div className="col-md-7">
                    <h1 className='animatable fadeInUp '>Desentralized Finance and Non Fungible Token</h1>
                    <p className='animatable fadeInUp '>Our NFT development company helps you to create your NFT platform with robust technology. NFT development process provides high customization freedom and allows you to become a frontrunner in the NFT field by availing of the services of our NFT development company.</p>  
                    <div className="btnMain animatable fadeInUp ">
                        <button className='btnGradient py-2 px-5 mr-3'>Connect with Whatsapp</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
