import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

import review1 from '../../images/review1.png'
import review2 from '../../images/review2.png'
import review3 from '../../images/review3.png'
import ratingStar from '../../images/ratingStar.png'
import effect1 from '../../images/effect1.png'
import lars from '../../images/lars.webp'
import berg from '../../images/berg.webp'
import raza from '../../images/raza.webp'
import carmelo from '../../images/carmello.webp'
import psii from '../../images/psincome.webp'

export const Section8 = () => {
    const settingsTestimonial = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
         
        ]
      };

      const teamData = [
        {
          name:"Carmelo Ippolito",
          designation:"Apes Planet",
          description:" “The most reliable team I've worked with, they always find a solution, are fast and professional. Excellent experience.” ",
          profile:carmelo,
        },
        {
          name:"Raza",
          designation:"Trustvibes",
          description:" “It was a great experience working with Dappomatics Team. These guys were thorough professionals and very passionate about the value they bring to the table. We had an amazing response time which is extremely important in the blockchain space.” ",
          profile:raza,
        },
        {
          name:"Lars Fabricus",
          designation:"CEO & FOUNDER of Roburna Blockchain",
          description:" “Dappomatics team is responsble for creating our roburna blockchain, and I must say they are dedicated to their work. Highly experienced team with strong communication skills” ",
          profile:lars,
        },
        {
          name:"Bjorn Mattens",
          designation:"Passive Income",
          description:" “We are amazed and grateful to the dappomatics team for handling our developement tasks with so ease and perfection. Overall a great software development company.” ",
          profile:psii,
        },
        {
          name:"Hassan Shahid",
          designation:"CEO of Berg Media",
          description:" “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
          profile:berg,
        }
      ];
  return (
    <section className='testmonialSec' id='sec8'>
        <img src={effect1} alt="effect1" className="effect" />

        <div className="container">
            <h1 className='pr'>Testimonials</h1>
            <div className="px-3">
                <Slider {...settingsTestimonial}>
                    {teamData.map((item,key)=>(
                        <div className=" mb-4 p-3 animatable fadeInUp" key={key}>
                            <TestCard image={item.profile} name={item.name} desig={item.designation} desc={item.description} />
                        </div>
                    ))}
                   
                </Slider>
                {/* <div className="col-lg-4 mb-4 animatable fadeInUp ">
                    <TestCard image={review1} name={"Christopher Smith"} desig={"Investor"} desc={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."} />
                </div>
                <div className="col-lg-4 mb-4 animatable fadeInUp animationDelay200">
                    <TestCard image={review2} name={"James Brian"} desig={"Trader"} desc={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."} />
                </div>
                <div className="col-lg-4 mb-4 animatable fadeInUp animationDelay400">
                    <TestCard image={review3} name={"Max Maximof"} desig={"Investor"} desc={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."} />
                </div> */}
            </div>
        </div>
    </section>
  )
}

const TestCard = ({image,name,desig,desc}) =>{
    return(
        <div className="testCard">
            <div className="starImg">
                <img className='img-fluid' src={ratingStar} alt="" />
            </div>
            <ReadMore >
            {desc} 
            </ReadMore>
            {/* <div className="desc">
            {desc} 
            </div> */}
            <div className="profileDiv">
                <img className='img-fluid' src={image} alt="review" />
                <div className='ml-2'>
                    <div className="name">{name}</div>
                    <div className="desig">{desig}</div>
                </div>
            </div>
        </div>
    )
}

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const [boxHeight, setboxHeight] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    // useEffect(()=>{
    //     var clientHeight = document.getElementById('desc').clientHeight;
    //     console.log(clientHeight);
    //     setboxHeight(clientHeight)
    // },[])

    return (
        <>
        <div className={isReadMore ?"desc show5Line":"desc"} id='desc' >
            {text}
        </div>
        <span onClick={toggleReadMore} className="desc cursor-pointer" >
            {isReadMore  ? "read more" : " show less"}
        </span>
        </>
    );
  };