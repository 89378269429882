import React, { useState,useEffect,useRef } from 'react';
import ReactDOM from 'react-dom'

import Typist from 'react-typist';
import Slider from "react-slick";
import {NavLink} from 'react-router-dom'


import Marquee from "react-fast-marquee";

import { Modal, Button } from "react-bootstrap"

import $ from "jquery";

import {animate} from '../assets/animate';

import logo from '../images/Logo.webp'
import logoSM from '../images/logoSM.svg'
import arrowRight from '../images/arrowRight.svg'

import serviceIcon from '../images/serviceIcon.webp'
import serviceElement from '../images/serviceElement.webp'


import block1 from '../images/block1.webp'
import block2 from '../images/block2.webp'
import block3 from '../images/block3.webp'
import block4 from '../images/block4.webp'
import mail from '../images/mail.svg'
import contact from '../images/contact.svg'

import { Navbar } from './Navbar';

import client from '../images/client.webp'
import experience from '../images/experience.webp'
import ongoingProject from '../images/ongoingProject.webp'
import project1 from '../images/project.webp'

import planet from '../images/planet.webp'
import trustVibe from '../images/trustVibe.svg'
import roburna from '../images/roburna.webp'
import certifypk from '../images/certifypk.webp'
import daoLogo from '../images/dao-logo.webp'
import bitcoinpk from '../images/bitcoinpk.webp'
import FortKnoxster from '../images/FortKnoxster.svg'
import blockpix from '../images/blockpix.svg'
import empireToken from '../images/empireToken.svg'
import motorLogo from '../images/motor.webp'
import studio from '../images/studio.webp'
import shiba from '../images/shiba.webp'
import passiveIncome from '../images/passiveIncome.webp'
// import { ReactComponent as ArrowDown } from "../images/arrowDown.svg";
import arrowDown from '../images/arrowDown.gif'
import availableEffect from '../images/availableEffect.png'
import availableEffect1 from '../images/availableEffect1.png'
import logoDark from '../images/logoDark.png'


// new section

import { Section8 } from './pages/Section8'
import { Section10 } from './pages/Section10'
import { Section12 } from './pages/Section12'
import { Section17 } from './pages/Section17'

import { Section26 } from './pages/Section26'
import { Section28 } from './pages/section28';




// new section





function Home({handleShow,darkMode,handleDarkMode}) {

  var [currentDemo,setCurrentDemo] =useState({})

  var [project,setproject] =useState(0)
  var [customer,setcustomer] =useState(0)
  var [developer,setdeveloper] =useState(0)
  var [clientCount,setClientCount] =useState(0)

  var [counterData,setCounterData] =useState({
    project:1,
    customer:1,
    developer:0,
    loc:1,
  })
  const [show, setShow] = useState(false);

  const handleDemoClose = () => setShow(false);
  const handleDemoShow = (item) => {
      setShow(true)
    setCurrentDemo(item)
    console.log(item);
}

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };




  const specialServiceData1 = [
    {
      name:"DAPPS DEVELOPMENT",
      description:"From Web3.0 integrations to any kind of token integration dappomatics offering all kinds of solution, We've an experience of building various kind of decentralized application. ",
       
    },
    {
      name:"SOLIDITY DEVELOPMENT/Auditing",
      description:"We build solidity smartcontracts with focusing mostly on security and gas optimization. Dappomatics offers development of smartcontract with any protocols like (ERC20, ERC721, ERC721A, ERC1155, ERC165, ERC884, and ETC) and any customized solution you need, also offering detailed testing and audit reports for your contracts",
      category:[
        "Cutomized Solidity Contracts",
        "Staking Contracts",
        "Escrow Contracts",
        "Integrating Decentralized Oracles",
        "Audit Reports"
      ]
    },
    {
      name:"NFT DEVELOPMENT SOLUTIONS",
      description:`We are developing all NFT related applications and also suggesting best solutions for theirs project. <br />  We're offering <br />  `,
       category:[
        "NFT Token Development",
        "NFT Marketplace Development", 
        "Cross-Chain Nft Development", 
        "NFT Art Tokenization", 
        "NFT Lending Development"
      ]
   
    },
    {
      name:"LAUNCHPAD PLATFORM DEVELOPMENT",
      description:"",
       
    },
    {
      name:"DIGITAL TOKENIZATION",
      description:"",
       
    },
    {
      name:"DAO GOVERNANCE",
      description:"Knowing the potential of DAO, our experts started to provide DAO related services and solutions. We have transformed into a decentralized self-organized DAO development company that has expanded DAO related services such as DAO platform development, DAO application development, DAO powered NFT platform development and more.",
      category:[
        "Voting Token Development",
        "DAO Governance Protocols Development",
        "DAO Rewards EcoSystem"
      ]      
    },
];
  const specialServiceData2 = [
    {
      name:"CUSTOM BLOCKCHAIN DEVELOPMENT",
      description:"Blockchain technologies are driving disruption in multiple industries including: Health Care, Education, Fintech, Insurance, supply chain, and ETC.<br /> Dappomatics blockchain development service helps businesses plan, build, and implement applications, assets, and functions.<br /> We help partners select the most appropriate blockchain technology from a range of industry leaders, including BitShares, EOS, Hyperledger, and Ethereum.",
      category:[
        "POS/POW Blockchains Development",
        "Ethereum Fork",
        "Customized Blockchain Network",
        "HyperLedger",
        "Lightning Network"
      ] 
    },
    {
      name:"MULTICHAIN SOLUTIONS",
      description:"",
       
    },
    {
      name:"BLOCKCHAIN CONSULTANCY",
      description:"We share our experiences on the projects  we have done. Our team understands the pros and cons of different platforms. We will provide strategic consulting to hands on expereince and provide custom build blockchain solutions.  Dappomatics biggest strength as blockchain consulting company is our experience in R&D on various web3 and blockchain stacks.",
       
    },
    {
      name:"BLOCKCHAIN TRAININGS",
      description:"Become a well-paid blockchain developer by joining dappomatics blockchain developer bootcamp. You will acquire all the necessary skills  to enter the blockchain industry as a developer. We will teach you how to create your first blockchain application with Ethereum, Web3.js and Solidity smart contracts. You don't need to know anything about blockchain to keep track. Dappomatics will teach you from scratch. Use this step-by-step guide with sample code and written instructions to start your blockchain development journey.",
       
    },
    {
      name:"SOLANA DEVELOPMENT",
      description:"",
      category:[
        "Solana Marketplace",
        "Candymachine Development",
        "Gumdrop",
        "Metaplex",
        "SPL Tokens Development"
      ]
       
    },
    {
      name:"WEB APPLICATION SOLUTIONS",
      description:"",
      category:[
        "Web Apps Designing",
        "Ecommerce Web Apps",
        "Customized Dashboards",
        "Logistics Web Apps"
      ]
    },
];




  function submit(e){
    e.preventDefault()
    console.log("working");
  }
 useEffect(()=>{
  animate()
  CounterAnimate()
 },[])



 function CounterAnimate() {
      
  // Function which adds the 'animated' class to any '.animatable' in view
  var doAnimations = function() {
  "use strict";
    
      // Calc current offset and get all animatables
      var offset = $(window).scrollTop() + $(window).height(),
          $counterSec = $('.counterSec');
      
      // Unbind scroll handler if we have no counterSec
      if ($counterSec.length == 0) {
        $(window).off('scroll', doAnimations);
      }
      
      // Check all counterSec and animate them if necessary
          $counterSec.each(function(i) {
         var $counterS = $(this);
              if (($counterS.offset().top + $counterS.height() - 20) < offset) {
              $counterS.removeClass('counterSec').addClass('counterOn');
              // console.log(offset);
              handleCounter()
              handleCustomersData()
              }
               
      });
  
      };
    
    // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
    $(window).trigger('scroll');

}
  const handleCounter =() =>{
 

    var startproject=project
    var endproject= 44;
    var startCustomer=customer
    var endCustomer= 10;
    var startDeveloper=developer
    var endDeveloper= 6;
    var startClient=clientCount
    var endClient= 46;

    var projectCounting = setInterval(function(){
        if(startproject < endproject) {
            startproject++
        } else {
            clearInterval(projectCounting)
        }
        // console.log(startproject);
        setproject(startproject)
    }, 10);

    var developerCounting = setInterval(function(){
        if(startDeveloper < endDeveloper) {
          startDeveloper++
        } else {
            clearInterval(developerCounting)
        }
        // console.log(startDeveloper);
        setdeveloper(startDeveloper)
    }, 100);

    var clientCounting = setInterval(function(){
        if(startClient < endClient) {
          startClient++
        } else {
            clearInterval(clientCounting)
        }
        // console.log(startClient);
        setClientCount(startClient)
    }, 100);
  }

  const handleCustomersData =() =>{
    var startCustomer=customer
    var endCustomer= 7;

    var customerCounting = setInterval(function(){
      if(startCustomer < endCustomer) {
        startCustomer++
      } else {
          clearInterval(customerCounting)
      }
      // console.log(startCustomer);
      setcustomer(startCustomer)
  }, 10);
  }

  return (
  <div className={darkMode?" darkMode overflow-hidden poppins":" lightMode overflow-hidden poppins"}>
    <header className='header fixed-top mainNavbar' id="navbar">
        <div className="container">
        <Navbar handleShow ={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} />
        </div>
      </header>
    <header className="header homeHeader pt-86 pb-50">
    {darkMode &&
      <>
      <img className='effect' src={availableEffect} alt="availableEffect1" />
      <img className='effect1' src={availableEffect} alt="availableEffect1" />
      </>
    }

      <section className="container ">
      

          <div className="row headerRow">
            <div className="col-md-12">
              <div className='text-center'>
                {/* <h1>We Are Dappomatics</h1> */}
                <h1>
                    <Typist hideWhenDone={false} avgTypingDelay={150} startDelay={400} > 
                      We Are <br /> Dappomatics
                    </Typist>
                </h1>
                <h6 data-animation="slideInUp" data-animation-delay="150ms">Building Decentralized Apps</h6>
                <div className='homeSignBtn'>
                  <button className="sigupBtn ml-0" onClick={()=>handleShow("Let's Talk")}>
                    Let's Talk 
                    <img className='ml-1' src={arrowRight} alt="arrow" width="13px" height="13px" />
                  </button>
                    <div className='shadow'></div>
                </div>
                <p>Trusted by 100+ clients</p>
                <div className='arrowDownImg'>
                  <img className='img-fluid' src={arrowDown} alt="down"  width="88px" height="88px"/>
                  {/* <ArrowDown /> */}
                </div>
                <div className='merchartsMain'>
                  
                </div>
                
              </div>
            </div>
            
          </div>

      
      </section>
    </header>
    <div className={darkMode?"defiPlatDevPage darkMode":"defiPlatDevPage lightMode"}>
      <Section10 handleShow={handleShow} />
    </div>
    
  {/* special service section */}
  <section className='specialServiceSec'>
  {darkMode && 
  <>
    <img className='effect' src={availableEffect1} alt="availableEffect1" />
    <img className='element1' src={serviceElement} alt="serviceElement" width={240} height={643.6} />
  </>
  }
    <div className="container">
      <h2 className='pr'>Our <br /> Special Services</h2>
      <div>
      <Slider {...settings}>
          <div>
      <div className="row mx-0 pt-3">
          {specialServiceData1.map((item,id)=>(

        <div className="col-lg-4 col-md-6">
          <div className="serviceCard animatable fadeInUp ">
            <div className='content'>
              <div className='innerContent'>
                <img className='icon' src={serviceIcon} alt="serviceIcon" width="70px" height="70px" />
                <h6>{item.name}</h6>
              </div>
              <div className='desc' dangerouslySetInnerHTML={{__html: item.description}} />
            </div>
            <div className="btnDiv">
              {/* <button className='btn readMore'>Read More</button> */}
              {/* <button className='btn readMore'></button> */}
              <div className="demoBtnDiv">
                <div className="shadow"></div>
                <button className='btn getDemo' onClick={()=>handleDemoShow(item)}>Get a demo <img src={arrowRight} alt="get_a_demo" width="13px" height="13px"/></button>
              </div>
            </div>
          </div>
        </div>

          ))}
       

      </div>
          </div>
          <div>
          <div className="row mx-0 pt-3">
          {specialServiceData2.map((item,id)=>(

            <div className="col-lg-4 col-md-6">
                <div className="serviceCard animatable fadeInUp ">
                    <div className='content'>
                    <div className='innerContent'>
                        <img className='icon' src={serviceIcon} alt="service" width="70px" height="70px"/>
                        <h6>{item.name}</h6>
                    </div>
                    <div className='desc' dangerouslySetInnerHTML={{__html: item.description}} />
                    </div>
                    <div className="btnDiv">
                    {/* <button className='btn readMore'>Read More</button> */}
                    {/* <button className='btn readMore'></button> */}
                    <div className="demoBtnDiv">
                    <div className="shadow"></div>
                      <button className='btn getDemo' onClick={()=>handleDemoShow(item)}>Get a demo <img src={arrowRight} alt="get_a_demo" width="13px" height="13px"/></button>
                    </div>                    
                  </div>
                </div>
            </div>

  ))}

          </div>
          </div>
        
        </Slider>
      </div>
    </div>
  </section>

  {/* <section className='projectCounter' >
    <div className='container'>
      <div className="shadowMain">
          <div className="shadow"></div>
        <div className="counterMain">
          <div className="countCard counterSec" >
            <div className="imgDiv">
              <i class="fas fa-2x fa-briefcase"></i>
            </div>
            <h3>{project}</h3>
            <p>Projects</p>
          </div>
          <div className="countCard counterSec">
            <div className="imgDiv">
              <i class="fas fa-2x fa-user"></i>
            </div>
            <h3>{customer}</h3>
            <p>Customers</p>
          </div>
          <div className="countCard">
            <div className="imgDiv">
            <i class="fas fa-2x fa-code"></i>
            </div>
            <h3>250000</h3>
            <p>Line of code</p>
          </div>
          <div className="countCard">
            <div className="imgDiv">
              <i class="fas fa-2x fa-user-circle"></i>
            </div>
            <h3>{developer}</h3>
            <p>Developers</p>
          </div>
        </div>
      </div>

    </div>
  </section> */}

  <section className='projectCounter1' >
    <div className='container'>
      <div className="shadowMain">
        <div className="shadow"></div>
        <div className="counterMain">
          <div className="countCard counterSec" >
            <div className="imgDiv">
              {/* <i class="fas fa-2x fa-briefcase"></i> */}
              <img src={project1} alt="Finished_Projects" width="64px" height="64px" />
            </div>
            <div className="contentCard">
              <h3>{project}</h3>
              <p>Finished Projects</p>
            </div>
          </div>
          <div className="countCard counterSec">
            <div className="imgDiv">
              {/* <i class="fas fa-2x fa-user"></i> */}
              <img src={ongoingProject } alt="Ongoing_Projects" width="64px" height="64px" />
            </div>
            <div className="contentCard">
              <h3>{customer}</h3>
              <p>Ongoing Projects</p>
            </div>
          </div>
          <div className="countCard">
            <div className="imgDiv">
            {/* <i class="fas fa-2x fa-code"></i> */}
            <img src={client} alt="Happy_client" width="64px" height="64px" />
            </div>
            <div className="contentCard">
              <h3>{clientCount}</h3>
              <p>Happy client</p>
            </div>
          </div>
          <div className="countCard">
            <div className="imgDiv">
              {/* <i class="fas fa-2x fa-user-circle"></i> */}
              <img src={experience} alt="experience" width="64px" height="64px" />
            </div>
            <div className="contentCard">
              <h3>{developer }</h3>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  {/* special service section */}
  <section className='ourProject'>
    <div className="container">
        <h2>Our <br /> Projects</h2>
          <Marquee speed={47}> 
        <div className='row row1 r2c1 jc-center'>
            <div className=" projectCardMain">
              <div className="projectCard">
                <img src={blockpix} alt="Blockpix" width={160} height={33.28} />
              </div>
            </div>
            <div className=" projectCardMain">
              <div className="projectCard">
                <img src={trustVibe} alt="trustVibes" width={160} height={48.4} />
              </div>
            </div>
        </div>
        <div className='row row1  r2c1 jc-center'>
            <div className=" projectCardMain">
              <div className="projectCard">
                <img src={planet} alt="apesPlanet" width={100} height={100} />
              </div>
            </div>
            <div className=" projectCardMain">
              <div className="projectCard">
                <img src={empireToken} alt="empireToken" width={100} height={33.3} />
              </div>
            </div>
        </div>
          </Marquee>

        <Marquee speed={47} > 
        <div className='row row2 r2c1'>
          
          <div className="projectCardMain">
            <div className="projectCard backgroundGradient180">
              <img className='filter-invert' src={motorLogo} alt="motor" width={160} height={70.4} />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={certifypk} alt="certifypk" width={160} height={63.7} />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard ">
              <img src={roburna} alt="roburna" width={100} height={100} />
            </div>
          </div>
         
        </div>
        <div className='row row2 r2c1'>

          <div className="projectCardMain">
            <div className="projectCard backgroundGradient180">
              <img src={daoLogo} alt="dao" width={160} height={48.8} />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={shiba} alt="shiba" width={100} height={100} />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard ">
              <img src={studio} alt="studio" width={160} height={175.6} />
            </div>
          </div>
         
        </div>
        </Marquee>

        <Marquee speed={47} > 
        <div className='row row3 r2c1 jc-center'>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={bitcoinpk} alt="bitcoinpk" width={165} height={39}  />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={FortKnoxster} alt="FortKnoxster" width={160} height={24.4} />
            </div>
          </div>
        
        </div>
        <div className='row row3 r2c1 jc-center'>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={passiveIncome} alt="passiveIncome" width={160} height={83.9} />
            </div>
          </div>
          <div className="projectCardMain">
            <div className="projectCard">
              <img src={FortKnoxster} alt="FortKnoxster" width={160} height={24.4} />
            </div>
          </div>
        
        </div>
        </Marquee>

        
        
    </div>
  </section>


  <div className={darkMode?"defiPlatDevPage darkMode":"defiPlatDevPage lightMode"}>
    <Section12 />
    <Section8 />
  </div>
  <Section28 />

  <section className={darkMode?"defiPlatDevPage blockchainSec darkMode":"defiPlatDevPage blockchainSec lightMode"} >
  {darkMode && 
    <img className='effect' src={availableEffect1} alt="availableEffect1" />
  }
    <div className="container">
      <h2 className='pr'>Our <br /> Blockchain Services</h2>
      <div className="row">
        <div className="col-md-6 animatable fadeInUp ">
          <BlockCard button="Development" title="SOLIDITY DEVELOPMENT" desc="Our smart contract development for decentralized applications can help you achieve your business goals." image={block1} />
        </div>
        <div className="col-md-6 animatable fadeInUp animationDelay200">
          <BlockCard button="Development" title="CRYPTO PAYMENT GATEWAY" desc="We help you to build a crypto payment gateway with fastest transaction time, multiple integration of crypto currencies." image={block2} />
        </div>
        <div className="col-md-6 animatable ">
          <BlockCard button="Development" title="DAPPS DEVELOPMENT SOLUTIONS" desc="Our DApp ensures specific business logic and a high degree of scalability to enable  smart contract deployment for DApps." image={block3} />
        </div>
        <div className="col-md-6 animatable fadeInUp animationDelay200">
          <BlockCard button="Development" title="NEW BLOCKCHAIN NETWORK DEVELOPMENT" desc="We will provide strategic consulting to hands-on experience and provide custom build blockchain solutions." image={block4} />
        </div>
      </div>
      <div className='seeAllDiv animatable fadeInUpLong'>
          {/*<button className='btn seeAll '>
            See All
          </button>*/}
          <div className="shadow"></div>
      </div>
    </div>
  </section>
  <Section26 />


 
  <Section17 />
  

  <footer className={darkMode?"darkMode":"lightMode"}>
  {darkMode && 
    <>
      <img className='effect' src={availableEffect1} alt="availableEffect1" />
      <img className='effect1' src={availableEffect1} alt="availableEffect1" />
    </>
  }
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="col1">
            {darkMode ?
            <img src={logoDark} alt="Dappomatics" width={165} height={39} />
            :
            <img src={logo} alt="Dappomatics" width={165} height={39} />
            }
            <p>Build Decentralized Apps & grow Web3.0</p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="col2">

            <div className="footerNavMain">
              <div className="footerNav">
                <a href="#">Home</a>
                <a href="#">About Us</a>
                <a href="#">Blog</a>
                <a onClick={()=>handleShow()}>Contact</a>
              </div>
            </div>
            <div className="addressMain">
              <a href="mailto:Contact@mail.com" className='mail'><img src={mail} alt="mail" width={14.3} height={9.2} /> <span> contact@dappomatics.com</span></a>
              <a href="Tel:+92 335 2491559" className='contact'><img src={contact} alt="contact" width={13.3} height={13.3} /> <span> +92 335 2491559</span></a>
            </div>
          </div>
            
        </div>
      </div>
      <div className="alrightMain pr">
        <p>All rights reserved</p>
        <p>2022 Dappomatics Tech LLP ®</p>
      </div>
    </div>
  </footer>




  <Modal show={show} onHide={handleDemoClose}>
    
    <Modal.Body className={darkMode?'darkMode demoModal':'demoModal'}  >
      <div className="heading">
        <h2>{currentDemo?.name}</h2>
        <i class="fas fa-times " onClick={handleDemoClose}></i>
      </div>
      <div className="mainContent pb-0">
        <div className='desc' dangerouslySetInnerHTML={{__html: currentDemo?.description}} />
          {currentDemo.category &&
          <div className="row jc-center pt-4">
           { currentDemo?.category.map((item,key)=>(
              <div className="col-sm-4 mb-3" key={key}>
                <div className="descBox">
                  <h6>{item}</h6>
                </div>
              </div>
            ))}
          
          </div>
          }
        </div>
      <div className="mainContent">
      <button type="submit" className="btn sendBtn  d-block">Book a demo</button>
      </div>
      <div>

      </div>
      
    </Modal.Body>
    {/* <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button>
    </Modal.Footer> */}
  </Modal>




  </div>
    
  );
}

const BlockCard =({button,title,desc,image}) =>{
  return(
    <div className="blockCard ">
      <button className='btn developmentBtn'>{button}</button>
      <img src={image} alt="block" width="100%" height="100%" />
      <div className="content">
        <h6>{title}</h6>
        <p>{desc}</p>
      </div>
    </div>
  )
}



export {Home};
