import React from 'react'
import logoDark from '../../images/logoDark.png'
import logo from '../../images/Logo.webp'
import mail from '../../images/mail.svg'
import contact from '../../images/contact.svg'

export const Footer2 = ({darkMode,handleShow}) => {
  return (
    <footer className={darkMode?"darkF darkMode":"darkF lightMode"} >
        <div className="container">
        <div className="row">
            <div className="col-md-4">
            <div className="col1">
                {darkMode ?
                <img src={logoDark} alt="Dappomatics" width={165} height={39} />
                :
                <img src={logo} alt="Dappomatics" width={165} height={39} />
                }
                <p>Build Decentralized Apps & grow Web3.0</p>
            </div>
            </div>
            <div className="col-md-8">
            <div className="col2">

                <div className="footerNavMain">
                <div className="footerNav">
                    <a href="#">Home</a>
                    <a href="#">About Us</a>
                    <a href="#">Blog</a>
                    <a onClick={()=>handleShow()}>Contact</a>
                </div>
                </div>
                <div className="addressMain">
                <a href="mailto:Contact@mail.com" className='mail'><img src={mail} alt="" /> <span> contact@dappomatics.com</span></a>
                <a href="Tel:00000-000-00" className='contact'><img src={contact} alt="" /> <span> +92 335 2491559</span></a>
                </div>
            </div>
                
            </div>
        </div>
        <div className="alrightMain">
            <p>All rights reserved</p>
            <p>2022 Dappomatics Tech LLP ®</p>
        </div>
        </div>
    </footer>
  )
}
