import React from 'react'
import openSea from '../../images/openSea.png'
import binanceLogo from '../../images/binanceLogo.png'
import polygonLogo from '../../images/polygonLogo.png'

export const Section24 = () => {
  return (
    
    <section className='sec13' id='sec24'>
        <h1>We are Partnering with</h1>
        <div className="rowMain">
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <img className='img-fluid animatable fadeInUp' src={binanceLogo} alt="openSea" />
                    </div>
                    <div className="col-4">
                        <img className='img-fluid animatable fadeInUp animationDelay200' src={polygonLogo} alt="openSea" />
                    </div>
                    <div className="col-4">
                        <img className='img-fluid animatable fadeInUp animationDelay400' src={openSea} alt="openSea" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
