import phone from '../images/phone.svg'
import send from '../images/send.svg'
import { Navbar } from './Navbar'

export const NotFound = () => {
    document.title = "404 Page Not Found | Dappomatics"
    window.location.href = "https://dappomatics.com/404";
    const handleShowFromConatact =() => {

    }
    return(
        <div className="Main404">
            <div className="row">
                <h1>404 Page Not Found</h1>
                <br />
            </div>
            <div className="div404">
                <p style={{textAlign:"center"}}>
                    <a href="/">Go to Home </a>
                </p>
            </div>
        </div>
    )
}