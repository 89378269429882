import React, { useState,useEffect,useRef } from 'react';
import ReactDOM from 'react-dom'

import Typist from 'react-typist';
import Slider from "react-slick";
import {NavLink} from 'react-router-dom'


import Marquee from "react-fast-marquee";

import { Modal, Button } from "react-bootstrap"

import $ from "jquery";

import {animate} from '../assets/animate';

import logo from '../images/Logo.webp'
import logoSM from '../images/logoSM.svg'
import arrowRight from '../images/arrowRight.svg'

import mail from '../images/mail.svg'
import contact from '../images/contact.svg'


import canDo from '../images/canDo.webp'
import becomePartner from '../images/becomePartner.webp'
import hands from '../images/hands.webp'
import brochureImg from '../images/brochureImg.webp'
import arrowDown from '../images/arrowDown.gif'
import { Navbar } from './Navbar';
import availableEffect from '../images/availableEffect.png'
import availableEffect1 from '../images/availableEffect1.png'





function About({handleShow,darkMode,handleDarkMode}) {

  const [show, setShow] = useState(false);

  const handleDemoClose = () => setShow(false);
  const handleDemoShow = (item) => {
      setShow(true)
    console.log(item);
}

  function submit(e){
    e.preventDefault()
    console.log("working");
  }
 useEffect(()=>{
  animate()
 },[])


  return (
  <div className={darkMode?" darkMode overflow-hidden":" lightMode overflow-hidden"}>
  <header className='header fixed-top mainNavbar'>
      <div className="container">
      <Navbar handleShow ={handleShow} darkMode={darkMode} handleDarkMode={handleDarkMode} />
      </div>
  </header>
  <header className="header homeHeader pt-86 pb-50">
  {darkMode &&
    <>
    <img className='effect' src={availableEffect} alt="availableEffect1" />
    <img className='effect1' src={availableEffect} alt="availableEffect1" />
    </>
  }
    <section className="container ">
    

        <div className="row headerRow">
          <div className="col-md-12">
            <div className='text-center'>
              {/* <h1>We Are Dappomatics</h1> */}
              <h1>
                  <Typist hideWhenDone={false} avgTypingDelay={200} startDelay={500} > 
                    About <br /> Dappomatics
                  </Typist>
              </h1>
              <h6 data-animation="slideInUp" data-animation-delay="200ms">Building Decentralized Apps</h6>
              <div className='homeSignBtn'>
                <button className="sigupBtn ml-0" onClick={()=>handleShow("Let's Talk")}>
                  Let's Talk 
                  <img className='ml-1' src={arrowRight} alt="" />
                </button>
                  <div className='shadow'></div>
              </div>
              <p>Trusted by 100+ clients</p>
              <div className='arrowDownImg'>
                <img className='img-fluid' src={arrowDown} alt="" />
                {/* <ArrowDown /> */}
              </div>
              
            </div>
          </div>
          {/* <div className="col-md-6">
            <img className='navCard' src={headerImg} alt="dappomatics" />
          </div> */}
        </div>

     
    </section>
  </header>

  <section className='ourCompanySec'>
  {darkMode &&
    <img className='effect' src={availableEffect} alt="availableEffect1" />
  }


      <div className="container pr">
          <h6 className='comp'>Our Company</h6>
          <h1 className='compDesc'>We run all kinds of IT services that <br /> vow your <span> success</span></h1>
          <div className="row">
              <div className="col-xl-6 col-lg-5">
                  <div className='col1'>
                      <div className="expMain">
                        <h1 className='expNum'>06</h1>
                        <p>YEARS’ EXPERIENCE IN IT</p>
                      </div>
                      <h6>More About Our Success Stories</h6>
                  </div>
              </div>
              <div className="col-xl-6 col-lg-7">
                  <div className='col2'>
                     <p>Dappomatics specializes in technological and IT-related services such as product engineering, warranty management, building cloud, infrastructure, network, etc. We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</p>
                     <button className='btn'>Discover now</button>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section className='becomePartnerSec'>
      <div className="container">
          <div className="row">
              <div className="col-lg-4 col-md-6">
                  <div className="partnerCard card3">
                      <div className="imgDiv">
                          <img src={canDo} alt="canDo" />
                      </div>
                      <h5>What we can do?</h5>
                      <p>We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</p>
                    <a href="#" className='discover'>Discover now  <i className='fas fa-long-arrow-right'></i> </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6">
                  <div className="partnerCard ">
                      <div className="imgDiv">
                          <img src={becomePartner} alt="canDo" />
                      </div>
                      <h5>What experience can do?</h5>
                      <p>Our experience in implementing several blockchain projects without delay will improve your relationship with your customers.</p>
                    <a href="#" className='discover'>Discover now  <i className='fas fa-long-arrow-right'></i> </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6">
                  <div className="partnerCard card3">
                      <div className="imgDiv">
                          <img src={hands} alt="canDo" />
                      </div>
                      <h5>What we can achieve?</h5>
                      <p>With our vast experience in WEB3.0 revolution, Dappomatics together with our clients we can build the biggest WEB3.0 platform.</p>
                    <a href="#" className='discover'>Discover now  <i className='fas fa-long-arrow-right'></i> </a>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section className='brochureSec'>
      <div className="container">
          <div className="row ai-center">
              <div className="col-lg-6 order-lg-1 order-2">
                  <div className="col1">
                      <h6>Dappomatics</h6>
                      <h2>Get a copy of our brochure on <span > Brand New IT Tech.</span> </h2>
                      <button className='btn downloadBtn'>Coming Soon!</button>
                  </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                  <div className="imgDiv">
                    <img src={brochureImg} className='img-fluid' alt="" />
                  </div>
              </div>
          </div>
      </div>
  </section>


  <footer className={darkMode?"darkMode":"lightMode"}>
  {darkMode && 
    <>
      <img className='effect' src={availableEffect1} alt="availableEffect1" />
      <img className='effect1' src={availableEffect1} alt="availableEffect1" />
    </>
  }
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="col1">
            <img src={logo} alt="" />
            <p>Build Decentralized Apps & grow Web3.0</p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="col2">

            <div className="footerNavMain">
              <div className="footerNav">
                <a href="#">Home</a>
                <a href="#">About Us</a>
                <a href="#">Blog</a>
                <a onClick={()=>handleShow()}>Contact</a>
              </div>
            </div>
            <div className="addressMain">
              <a href="mailto:Contact@mail.com" className='mail'><img src={mail} alt="" /> <span> contact@dappomatics.com</span></a>
              <a href="Tel:00000-000-00" className='contact'><img src={contact} alt="" /> <span> +92 335 2491559</span></a>
            </div>
          </div>
            
        </div>
      </div>
      <div className="alrightMain">
        <p>All rights reserved</p>
        <p>2022 Dappomatics Tech LLP ®</p>
      </div>
    </div>
  </footer>



      </div>
    
  );
}

export {About};
