import React from 'react'
import codeImg from '../../images/codeImg.jpg'
import effect1 from '../../images/effect1.png'

export const Section19 = () => {
  return (
    
    <section className="secNine" id='sec19'>
        <img src={effect1} alt="effect1" className="effect1" />
        <div className="container pr">
            <h1>For Developers, By Developers</h1>
            <div className="row ai-center mt-5">
                <div className="col-md-6">
                    <div>
                        <div className="head animatable fadeInUp ">Create</div>
                        <div className="text animatable fadeInUp ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                        <button className="btn animatable fadeInUp ">Learn More</button>                        
                    </div>
                </div>
                <div className="col-md-6">
                <div className="bannerImg">
                    <img className='img-fluid' src={codeImg} alt="codeImg" />
                </div>
                </div>
            </div>
            <div className="row ai-center mt-5">
                <div className="col-md-6">
                    <div>
                        <div className="head animatable fadeInUp ">Release</div>
                        <div className="text animatable fadeInUp ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                        <button className="btn animatable fadeInUp ">Learn More</button>                        
                    </div>
                </div>
                <div className="col-md-6">
                <div className="bannerImg">
                    <img className='img-fluid' src={codeImg} alt="codeImg" />
                </div>
                </div>
            </div>
            <div className="row ai-center mt-5">
                <div className="col-md-6">
                    <div>
                        <div className="head animatable fadeInUp ">Deploy</div>
                        <div className="text animatable fadeInUp ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                        <button className="btn animatable fadeInUp ">Learn More</button>                        
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="bannerImg">
                        <img className='img-fluid' src={codeImg} alt="codeImg" />
                    </div>
                </div>
            </div>
            <div className="text-center mt-5">
                <button className='btnGradient animatable fadeInUp '>Try it on Replit</button>
            </div>
        </div>
    </section>
  )
}
