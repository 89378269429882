import React from 'react'
import linesRight from '../../images/linesRight.png'
import linesLeft from '../../images/linesLeft.png'
export const Section28 = () => {
  return (
    <div className="getin2Sec " id='sec28'>
        <img className='linesRight' src={linesRight} alt="" />
        <img className='linesLeft' src={linesLeft} alt="" />
    <div className="container">
        <div className="row jc-sb ai-center">
            <div className="col-lg-8">         
                <div className="col1">
                    <div className="head2  ">Your brand, like you have never seen it before</div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="col2">
                    <button className='whiteBtn'>Get In Touch</button>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
