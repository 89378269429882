import React from 'react'
import effect1 from '../../images/effect1.png'

export const Section25 = () => {
  return (
    
    <section className='faqSec' id='sec25'>
        <img src={effect1} alt="effect1" className="effect" />

        <div className="container pr">
            <h6>FAQ</h6>
            <h1>Get the facts</h1>

            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                <div className="panel panel-default  faq-ques">
                    <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title ">
                        <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
                        What is the Categories of NFTs?
                        </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body Poppins">
                        We have a provisional date in place, but this may change. Please stay connected to our community on discord for further updates on launch date and time!                    </div>
                    </div>
                </div>

                <div className="panel panel-default  faq-ques">
                    <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title ">
                        <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse12" aria-expanded="false" aria-controls="collapseTwo">
                        How NFTS work?
                        </a>
                        </h4>
                    </div>
                    <div id="collapse12" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body Poppins">
                        Sure. Well, there are 10,000 Unstable Horses bred at launch, so, we will have an online racing game in our ecosystem known GALLOP, where farm owners can race their horses against other owners. What determines the success of your horse winning races depends on the attributes, the breed and the terrain the race is on. More will be announced in regards to this through our discord.                    </div>
                    </div>
                </div>
                <div className="panel panel-default  faq-ques">
                    <div className="panel-heading" role="tab" id="headingThree">
                        <h4 className="panel-title ">
                        <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse13" aria-expanded="false" aria-controls="collapseThree">
                        How NFTS work?
                        </a>
                        </h4>
                    </div>
                    <div id="collapse13" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body Poppins">
                        Sure. Well, there are 10,000 Unstable Horses bred at launch, so, we will have an online racing game in our ecosystem known GALLOP, where farm owners can race their horses against other owners. What determines the success of your horse winning races depends on the attributes, the breed and the terrain the race is on. More will be announced in regards to this through our discord.                    </div>
                    </div>
                </div>

                <div className="panel panel-default  faq-ques">
                    <div className="panel-heading" role="tab" id="headingFour">
                        <h4 className="panel-title ">
                        <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse14" aria-expanded="false" aria-controls="collapseFour">
                        How to sell and Buy NFTS?
                        </a>
                        </h4>
                    </div>
                    <div id="collapse14" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div className="panel-body Poppins">
                        Sure. Well, there are 10,000 Unstable Horses bred at launch, so, we will have an online racing game in our ecosystem known GALLOP, where farm owners can race their horses against other owners. What determines the success of your horse winning races depends on the attributes, the breed and the terrain the race is on. More will be announced in regards to this through our discord.                    </div>
                    </div>
                </div>

            </div>

        </div>
    </section>
  )
}
