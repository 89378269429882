import React from 'react'
import nft_games from '../../images/nft_games.png'


export const Section3 = () => {
  return (
    <section className='secThree' id='sec3'>
        <div className="container">
            <div className="row ai-center">
                <div className="col-md-5">
                    <div className="imgDiv">
                        <img className='img-fluid' src={nft_games} alt="DEFI" />
                    </div>
                </div>
                <div className="col-md-7">
                    <h1 className='animatable fadeInUp '>NFT Development</h1>
                    <p className='animatable fadeInUp '>Our NFT development company helps you to create your NFT platform with robust technology. NFT development process provides high customization freedom and allows you to become a frontrunner in the NFT field by availing of the services of our NFT development company.</p>  
                    <div className="btnMain animatable fadeInUp ">
                        <button className='btnGradient py-2 px-5 mr-3'>Explore</button>
                        <button className='btnGradientoutline py-2 px-5'>Create</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
