import React from 'react'
import block1 from '../../images/block1.webp'
import block2 from '../../images/block2.webp'
import block3 from '../../images/block3.webp'
import block4 from '../../images/block4.webp'
import effect1 from '../../images/effect1.png'

export const Section13 = () => {
    
  return (
    <section className='blockchainSec ' id='sec13'>
        <img src={effect1} alt="effect1" className="effect" />
        <div className="container pr">
        <h2>Our <br /> Blockchain Services</h2>
        <div className="row">
            <div className="col-md-6 animatable fadeInUp ">
            <BlockCard button="Development" title="SOLIDITY DEVELOPMENT" desc="Our smart contract development for decentralized applications can help you achieve your business goals." image={block1} />
            </div>
            <div className="col-md-6 animatable fadeInUp animationDelay200">
            <BlockCard button="Development" title="CRYPTO PAYMENT GATEWAY" desc="We help you to build a crypto payment gateway with fastest transaction time, multiple integration of crypto currencies." image={block2} />
            </div>
            <div className="col-md-6 animatable ">
            <BlockCard button="Development" title="DAPPS DEVELOPMENT SOLUTIONS" desc="Our DApp ensures specific business logic and a high degree of scalability to enable  smart contract deployment for DApps." image={block3} />
            </div>
            <div className="col-md-6 animatable fadeInUp animationDelay200">
            <BlockCard button="Development" title="NEW BLOCKCHAIN NETWORK DEVELOPMENT" desc="We will provide strategic consulting to hands-on experience and provide custom build blockchain solutions." image={block4} />
            </div>
        </div>
        <div className='seeAllDiv animatable fadeInUpLong'>
            {/*<button className='btn seeAll '>
                See All
            </button>*/}
            <div className="shadow"></div>
        </div>
        </div>
    </section>
  )
}

const BlockCard =({button,title,desc,image}) =>{
    return(
      <div className="blockCard ">
        <button className='btn developmentBtn'>{button}</button>
        <img src={image} alt="block" width="100%" height="100%" />
        <div className="content">
          <h6>{title}</h6>
          <p>{desc}</p>
        </div>
      </div>
    )
  }
